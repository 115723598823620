import Vue from 'vue';
import Router from 'vue-router';
import RouterComponent from './components/RouterComponent.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "start" */ './pages/Start.vue'),
      children: [
        {
          path: 'login',
          name: 'Login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ './pages/auth/SigninPage.vue'),
        },
        {
          path: 'recover-password',
          component: () => import(/* webpackChunkName: "recover-password" */ './pages/auth/ForgotPassword.vue'),
        },
        {
          path: 'reset-password',
          component: () => import(/* webpackChunkName: "reset-password" */ './pages/auth/ResetPassword.vue'),
        },
        {
          path: 'main',
          component: () => import(/* webpackChunkName: "main" */ './pages/Main.vue'),
          children: [
            {
              path: 'dashboard/:viewName?',
              name: 'mainDashboard',
              component: () => import(/* webpackChunkName: "main-dashboard" */ './pages/dashboard/Dashboard.vue'),
            },
            {
              path: 'data-management',
              component: () => import(/* webpackChunkName: "main-dashboard" */ './pages/data-management/DataManagement.vue'),
              redirect: 'data-management/overview',
              children: [
                {
                  path: 'overview',
                  name: 'fileOverview',
                  component: () => import(/* webpackChunkName: "main-overview" */ './pages/data-management/FileOverview.vue'),
                },
                {
                  path: 'upload',
                  name: 'fileUpload',
                  component: () => import(/* webpackChunkName: "main-upload" */ './pages/data-management/FileUpload.vue'),
                },
                {
                  path: 'lut',
                  name: 'lutOverview',
                  component: () => import(/* webpackChunkName: "main-lut" */ './pages/data-management/LutOverview.vue'),
                },
                {
                  path: 'lut-details/:id',
                  name: 'lutDetails',
                  component: () => import(/* webpackChunkName: "main-lut-details" */ './pages/data-management/LutDetails.vue'),
                },
              ],
            },
            {
              path: 'categorylab',
              name: 'categorylab',
              component: () => import(/* webpackChunkName: "main-categorizer" */ './pages/categoryLab/CategoryLab.vue'),
              redirect: 'categorylab/dashboard',
              children: [
                {
                  path: 'dashboard',
                  name: 'categorylabDashboard',
                  component: () => import(/* webpackChunkName: "main-vendors-search" */ './pages/categoryLab/CategoryLabDashboard.vue'),
                },
                {
                  path: 'tool',
                  name: 'categorylabTool',
                  component: () => import(/* webpackChunkName: "main-vendors-search" */ './pages/categoryLab/CategoryLabTool.vue'),
                },
                {
                  path: 'rules',
                  name: 'categorylabRules',
                  component: () => import(/* webpackChunkName: "main-vendors-search" */ './pages/categoryLab/CategoryLabRules.vue'),
                },
                {
                  path: 'model',
                  name: 'categorylabModel',
                  component: () => import(/* webpackChunkName: "main-vendors-search" */ './pages/categoryLab/CategoryLabModel.vue'),
                },
              ],
            },
            {
              path: 'cluster',
              name: 'cluster',
              component: () => import(/* webpackChunkName: "main-demo" */ './pages/cluster/Cluster.vue'),
              redirect: 'cluster/list',
              children: [
                {
                  path: 'overview',
                  name: 'clusterOverview',
                  component: () => import(/* webpackChunkName: "main-vendors-detail" */ './pages/cluster/ClusterOverview.vue'),
                },

                {
                  path: 'list',
                  name: 'clusterList',
                  component: () => import(/* webpackChunkName: "main-vendors-detail" */ './pages/cluster/ClusterList.vue'),
                },
                {
                  path: 'rules',
                  name: 'clusterRules',
                  component: () => import(/* webpackChunkName: "main-vendors-detail" */ './pages/cluster/ClusterRules.vue'),
                },
              ]
            },
            {
              path: 'research',
              name: 'research',
              component: () => import(/* webpackChunkName: "main-vendors" */ './pages/research/Research.vue'),
              redirect: 'research/dashboard',
              children: [
                {
                  path: 'dashboard',
                  name: 'researchDashboard',
                  component: () => import(/* webpackChunkName: "main-vendors-detail" */ './pages/research/ResearchDashboard.vue'),
                },
                {
                  path: 'category/:categoryIdentifier?/:categoryName?',
                  name: 'researchCategoryDetail',
                  component: () => import(/* webpackChunkName: "main-vendors-detail" */ './pages/research/ResearchCategoryDetail.vue'),
                },
                {
                  path: 'vendor/:vendorID?/:vendorName?',
                  name: 'researchVendorDetail',
                  component: () => import(/* webpackChunkName: "main-vendors-detail" */ './pages/research/ResearchVendorDetail.vue'),
                },
                {
                  path: 'pivot',
                  name: 'researchPivot',
                  component: () => import(/* webpackChunkName: "main-vendors-detail" */ './pages/research/ResearchPivot.vue'),
                },
                {
                  path: 'unit',
                  name: 'unitPrices',
                  component: () => import(/* webpackChunkName: "main-vendors-detail" */ './pages/research/ResearchUnitPrices.vue'),
                },
                {
                  path: 'transactions',
                  name: 'researchTransactions',
                  component: () => import(/* webpackChunkName: "main-vendors-detail" */ './pages/research/ResearchTransactions.vue'),
                },
              ],
            },
            {
              path: 'kpi',
              name: 'kpi',
              component: () => import(/* webpackChunkName: "main-demo" */ './pages/kpi/KPI.vue'),
              redirect: 'kpi/dashboard',
              children: [
                {
                  path: 'dashboard',
                  name: 'kpiDashboard',
                  component: () => import(/* webpackChunkName: "main-vendors-detail" */ './pages/kpi/KPIDashboard.vue'),
                },
              ],
            },
            {
              path: 'actions',
              name: 'actions',
              component: () => import(/* webpackChunkName: "main-demo" */ './pages/actions/Actions.vue'),
              redirect: 'actions/projects',
              children: [
                {
                  path: 'projects/:projectID?',
                  name: 'actionLog',
                  component: () => import(/* webpackChunkName: "main-vendors-detail" */ './pages/actions/ActionLog.vue'),
                },
                {
                  path: 'savings',
                  name: 'savingsDashboard',
                  component: () => import(/* webpackChunkName: "main-vendors-detail" */ './pages/savings/SavingsDashboard.vue'),
                },
              ],
            },
            {
              path: 'spend-insights',
              name: 'spendInsights',
              component: () => import(/* webpackChunkName: "main-demo" */ './pages/insights/SpendInsights.vue'),
            },
            {
              path: 'reports',
              name: 'reports',
              component: () => import(/* webpackChunkName: "main-demo" */ './pages/reports/Reports.vue'),
              redirect: 'reports/dashboard',
              children: [
                {
                  path: 'dashboard',
                  name: 'reportsDashboard',
                  component: () => import(/* webpackChunkName: "main-vendors-detail" */ './pages/reports/ReportsDashboard.vue'),
                },
              ],
            },
            /*{
              path: 'documentation',
              name: 'documentation',
              component: () => import(/* webpackChunkName: "main-demo" */ /*'./pages/docu/Documentation.vue'),
},*/
            {
              path: 'category-classification',
              name: 'categoryClassification',
              component: () => import(/* webpackChunkName: "main-demo" */ './pages/demo/CategoryClassification.vue'),
            },
            {
              path: 'admin',
              component: () => import(/* webpackChunkName: "main-admin" */ './pages/admin/Admin.vue'),
              redirect: 'admin/users/all',
              children: [
                {
                  path: 'organization/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-organization" */ './pages/admin/AdminOrganizations.vue'),
                },
                {
                  path: 'organization/edit/:dbName',
                  name: 'main-admin-organization-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-organization" */ './pages/admin/EditOrganization.vue'),
                },
                {
                  path: 'organization/create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-organization" */ './pages/admin/CreateOrganization.vue'),
                },
                {
                  path: 'users/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './pages/admin/AdminUsers.vue'),
                },
                {
                  path: 'users/edit/:id',
                  name: 'main-admin-users-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-edit" */ './pages/admin/EditUser.vue'),
                },
                {
                  path: 'users/create',
                  name: 'main-admin-users-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-create" */ './pages/admin/CreateUser.vue'),
                },
                {
                  path: 'facts',
                  name: 'facts',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-create" */ './pages/admin/Facts.vue'),
                },
                {
                  path: 'organization/settings',
                  component: () => import(
                    /* webpackChunkName: "main-admin-organization" */ './pages/admin/Settings.vue'),
                },
                {
                  path: 'organization/etl',
                  component: () => import(
                    /* webpackChunkName: "main-admin-organization" */ './pages/admin/ETL.vue'),
                },
                {
                  path: 'categorizer/schema',
                  component: () => import(
                    /* webpackChunkName: "main-admin-organization" */ './pages/admin/CategorizerSchema.vue'),
                },
                {
                  path: 'case',
                  component: () => import(
                    /* webpackChunkName: "main-admin-organization" */ './pages/admin/SalesCaseAdmin.vue'),
                },
              ],
            },
            {
              path: 'profile',
              component: RouterComponent,
              redirect: 'profile/view',
              children: [
                {
                  path: 'view',
                  component: () => import(
                    /* webpackChunkName: "main-profile" */ './pages/profile/UserProfile.vue'),
                },
                {
                  path: 'edit',
                  component: () => import(
                    /* webpackChunkName: "main-profile-edit" */ './pages/profile/UserProfileEdit.vue'),
                },
                {
                  path: 'password',
                  component: () => import(
                    /* webpackChunkName: "main-profile-password" */ './pages/profile/UserProfileEditPassword.vue'),
                },
              ],
            },
          ],
        },
        {
          path: 'c/:token?',
          component: () => import(/* webpackChunkName: "case" */ './pages/case/Case.vue'),
        },
        //{
        //  path: 'actions/',
        //  component: () => import(/* webpackChunkName: "case" */ './pages/actions/ActionLog.vue'),
        //},
        {
          path: 'imprint',
          name: 'imprint',
          component: () => import(/* webpackChunkName: "case" */ './pages/meta/Imprint.vue'),
        },
        {
          path: 'privacy-policy',
          name: 'privacyPolicy',
          component: () => import(/* webpackChunkName: "case" */ './pages/meta/PrivacyPolicy.vue'),
        },
      ],
    },
    {
      path: '/*', redirect: '/',
    },
  ],
});
