export default {
  // general translations
  common: {
    add: 'Add',
    cancel: 'Cancel',
    description: 'Description',
    delete: 'Delete',
    title: 'Title',
    save: 'Save',
    faq: 'FAQ',
    contact: 'Contact Us',
    tos: 'Terms of Service',
    policy: 'Privacy Policy',
    welcome: 'Welcome',
    goodMorning: 'Good morning',
    goodAfternoon: 'Good afternoon',
    goodEvening: 'Good evening',
    on: 'on',
    off: 'off',
    yes: 'Yes',
    no: 'No',
    chooseColumns: 'Select columns',
    required: 'required',
  },
  usermenu: {
    profile: 'Profile',
    signin: 'Sign In',
    dashboard: 'Dashboard',
    signout: 'Sign Out'
  },
  error: {
    notfound: 'Page Not Found',
    other: 'An Error Ocurred'
  },
  check: {
    title: 'Set New Password',
    backtosign: 'Back to Sign In',
    newpassword: 'New Password',
    button: 'Set new password and Sign in',
    error: 'The action link is invalid',
    verifylink: 'Verifying link...',
    verifyemail: 'Verifying email address...',
    emailverified: 'Email verified! Redirecting...'
  },
  forgot: {
    title: 'Forgot Password?',
    subtitle: 'Enter your account email address and we will send you a link to reset your password.',
    email: 'Email',
    button: 'Request Password Reset',
    backtosign: 'Back to Sign In'
  },
  login: {
    title: 'Sign In',
    email: 'Email',
    password: 'Password',
    button: 'Sign In',
    orsign: 'Or sign in with',
    forgot: 'Forgot password?',
    noaccount: 'Don\'t have an account?',
    create: 'Create one here',
    error: 'The email / password combination is invalid',
    'sso': 'Sign in with external service'
  },
  button: {
    goToTool: 'To the tool',
    yes: 'Yes',
    cancel: 'Cancel',
    submit: 'Submit',
    detailView: 'Detailed view',
    goToTransactions: 'Jump to individual transactions',
    goToCategoryLab: 'Jump to CategoryLab with current filtering',
    goToDownload: 'Download data',
    goToCategoryLabWarning: 'To jump into CategoryLab, please activate the CategoryLab categories first',
    search: 'Search',
  },
  'dataTable': {
    'limitWarning1': 'Only the first ',
    'limitWarning2': '  elements are displayed.',
    'limitWarningForTableSort': 'The sorting of the table refers only to these elements.',
  },
  form: {
    validation: {
      requiredValue: 'Must exist',
      onlyNumbers: 'Only numbers allowed',
      year: 'Year must consist of 4 numbers',
    },
  },
  // Vuetify components translations
  $vuetify: {
    badge: 'Badge',
    close: 'Close',
    dataIterator: {
      noResultsText: 'No matching records found',
      loadingText: 'Loading items...'
    },
    dataTable: {
      itemsPerPageText: 'Rows per page:',
      ariaLabel: {
        sortDescending: 'Sorted descending.',
        sortAscending: 'Sorted ascending.',
        sortNone: 'Not sorted.',
        activateNone: 'Activate to remove sorting.',
        activateDescending: 'Activate to sort descending.',
        activateAscending: 'Activate to sort ascending.'
      },
      sortBy: 'Sort by'
    },
    dataFooter: {
      itemsPerPageText: 'Items per page:',
      itemsPerPageAll: 'All',
      nextPage: 'Next page',
      prevPage: 'Previous page',
      firstPage: 'First page',
      lastPage: 'Last page',
      pageText: '{0}-{1} of {2}'
    },
    datePicker: {
      itemsSelected: '{0} selected',
      nextMonthAriaLabel: 'Next month',
      nextYearAriaLabel: 'Next year',
      prevMonthAriaLabel: 'Previous month',
      prevYearAriaLabel: 'Previous year'
    },
    noDataText: 'No data available',
    carousel: {
      prev: 'Previous visual',
      next: 'Next visual',
      ariaLabel: {
        delimiter: 'Carousel slide {0} of {1}'
      }
    },
    calendar: {
      moreEvents: '{0} more'
    },
    fileInput: {
      counter: '{0} files',
      counterSize: '{0} files ({1} in total)'
    },
    timePicker: {
      am: 'AM',
      pm: 'PM'
    },
    pagination: {
      goto: 'Go to page',
      ariaLabel: {
        wrapper: 'Pagination Navigation',
        next: 'Next page',
        previous: 'Previous page',
        page: 'Goto Page {0}',
        currentPage: 'Current Page, Page {0}'
      }
    }
  },
  // kiresult translations
  navigation: {
    headlineDashboard: 'Overview & Data',
    headlineDataQuality: 'Data Quality',
    headlineAnalytics: 'Data Analytics',
    headlineMeta: 'Meta',
    headlineSuperSettings: 'Super-User',
    headlineSettings: 'Settings',
    dashboard: 'Dashboard',
    dataManagement: 'Data-Management',
    overview: 'Overview',
    upload: 'Upload',
    categorylab: 'CategoryLab',
    rules: 'Commodity Group Rules',
    model: 'Commodity Group Classification',
    spendAnalytics: 'Spend Analytics',
    spendAnalyticsDashboard: 'Spend Analysis Dashboard',
    categoryDetails: 'Category-Details',
    cluster: 'Harmonization',
    clusterOverview: 'Overview',
    clusterList: 'List of Clusters',
    clusterRules: 'Harmonization Rules',
    vendorDetails: 'Vendor-Details',
    pivot: 'Pivot-Table',
    transactions: 'Transactions',
    kpi: 'KPI',
    kpiDashboard: 'KPI-Dashboard',
    unitPrices: 'Unit-Prices',
    reportsDashboard: 'Reports & Download',
    spendInsights: 'CoPilot & Insights',
    savingsActions: 'Actions & Savings',
    savingsDashboard: 'Savings Dashboard',
    savingsProjects: 'Savings Projects',
    documentation: 'Documentation & FAQ',
    profile: 'User-Profile',
    settings: 'Settings',
    etl: "API",
    userManagement: 'User-Management',
    facts: 'Company information',
    globalOrganizations: 'Global organizations',
    settingsOfOrganization: 'Settings of organization',
    categorylabSchema: 'CategoryLab schema',
    salesCaseSettings: 'Sales Case settings',
    lutOverview: "Look-Up tables",
    toolbar: {
      docGlossary: 'Glossary',
      docCheatSheet: 'CheatSheet',
      docAssistant: 'Assistent',
      docAssistantBeta: '(Beta test) The functionality is not yet available.',
    },
    views: {
      labelViewMenu: 'Saved views',
      noViewsAvailable: 'No views available yet. Be the first one!',
      editViews: 'Manage views',
      showViews: 'Show views',
      addViews: 'Add view',
      applyView: 'Apply view',
      deleteConfirmMessage: 'Are you sure you want to delete this view?',
      ownView: 'Own views',
      globalView: 'Global views',
      saveCurrentSettingsAsView: 'Save current filter settings as a view',
      nameForNewView: 'Set name for the new view',
      descriptionForNewView: 'Set description for the view',
      name: 'name',
      description: 'description',
      typeOfView: 'Type of the view',
      actions: 'actions',
      availabilityOfView: 'Availability of this view',
      onlySaveForMyself: 'Save view only for myself',
      saveForAllUsers: 'Save view for all users',
      saveAsRoute: 'Only filter or also save page as link?',
      saveFiltering: 'Save current filters',
      saveFilteringAndSite: 'Save current filters and site',
      routeYes: 'Yes',
      routeNo: 'No',
      saveFilterSettings: 'Save filter settings',
      savedViewMessage: 'View created',
      viewAlreadyExistsMessage: 'A view with this name already exists. Your view is not saved.',
      pleaseProvideViewName: 'Please provide a name for this view.',
      emptyView: 'Create your first view using the toolbar above.',
      noDescription: 'No description available',
      details: {
        details: 'Details',
        filter: 'Filter',
        time: 'Time range',
        link: 'Link to page',
        description: 'Description',
        allExcept: 'All except',
      },
      links: {
        researchDashboard: 'Spend Analytics Dashboard',
        researchCategoryDetail: 'Category Details',
        researchVendorDetail: 'Vendor Details',
        researchPivot: 'Pivot Table',
        researchTransactions: 'Transactions',
      },
    },
  },
  docs: {
    docToVideo: 'Watch a video on this topic.',
    cheatSheet: {
      filter: {
        goToTransactionCategoryLabDownloadTitle: 'Transactions, CategoryLab, Download',
        goToTransactionCategoryLabDownloadMsg: 'Any filter set in the filter menu will be applied in all actions. "Transactions": Jump to the individual transactions behind the visualizations. - "CategoryLab": Jump directly to the CategoryLab and categorize your transactions. - "Download": A standard export will be sent to you by email.',
        activeFilterTitle: 'Active filter',
        activeFilterMsg: 'The number of currently active filters is displayed. All filters can be reset using the "Remove all filters" button (far right).',
        filterFieldTitle: 'Filter and search field',
        filterFieldMsg: 'Filters can be set easily. To do this, enter the name of the attribute to be filtered and select it from the list. In case the entered name does not match exactly, the most likely matches will be displayed at the top of the list. Additional filters can be used by expanding the Filter menu (far right).',
        categoryTypeTitle: 'Types of categories',
        categoryTypeMsg: 'There are several types of commodity groups available for visualizations and evaluations. "CategoryLab deactivated": Only the existing commodity groups in the uploaded data are used and no enrichment by kiresult takes place. - "CategoryLab" activated: The uploaded commodity groups are enriched by kiresult and by the work in CategoryLab. - "Vendor based" (optional): Instead of enriching commodity groups at the transaction level, matching commodity groups are assigned to suppliers.',
        harmonizationTitle: '',
        harmonizationMsg: 'When harmonization is switched on, the harmonized vendor groups are displayed and evaluated instead of the single vendors. Vendor groups are identified by the addition "H" in the vendor number. Also the filters and the search options refer to the harmonized vendor groups when activated. If in the settings other characteristics are defined to be harmonized, everything applies equivalently to them as well.',
        timeTitle: 'Time filter',
        timeMsg: 'The period of the data can be filtered flexibly. Further to the right, a comparison period can be set. This will display a growth factor next to each value in the toolbox. The time period can also be set by zooming into the time graph. The time filter should always be kept in mind, so that it is clear at any time which data is currently filtered.',
      },
      time: {
        timeChartTypesTitle: 'Time periodes',
        timeChartTypesMsg: 'For a better view of very large or very small time periods, the step width can be changed. This allows better identification of trends.',
      },
      navigation: {
        expandToolbarTitle: 'Expand toolbar',
        expandToolbarMsg: '',
      },
      vendor: {
        goToDetailViewTitle: 'Evaluation over all vendors',
        goToDetailViewMsg: 'Jump to an evaluation of all vendors. The filters set in the filter menu are applied.',
        goToDetailTitle: 'Further details',
        goToDetailMsg: 'Show details of a single vendor. Other filters set in the filter menu are applied.'
      },
      category: {
        goToDetailViewTitle: 'Evaluation over all commodity groups',
        goToDetailViewMsg: 'Jump to an evaluation of all commodity groups. The filters set in the filter menu are applied.',
        goToDetailTitle: 'Further details',
        goToDetailMsg: 'Show details of a single commodity group. Other filters set in the filter menu are applied.',
        drilldownTitle: 'Drilldown',
        drilldownMsg: 'Click on the name of the commodity group to jump into the commodity group and to the next level.',
        expandMoreInformationTitle: 'Expand more information',
        expandMoreInformationMsg: 'Click here to expand more information for this attribute. This way, you can quickly get an overview without having to set each element separately in the filter menu.',
      },
      dashboard: {
        totalSpendTitle: '',
        totalSpendMsg: 'Total spend in the toolbox. For this value, all uploaded data is evaluated without filters.',
        vendorCountTitle: '',
        vendorCountMsg: 'Total number of vendors in the toolbox. For this value, all uploaded data is evaluated without filters.',
        spendInsightsCardTitle: 'Demo for Spend-Insights',
        spendInsightsCardMsg: 'The module serves only as a demo visualization. All data in the demo are simulated and are not related to the rest of the toolbox.',
      },
      fileOverview: {
        goToRefreshUploadTitle: 'Refresh, Upload',
        goToRefreshUploadMsg: '"Refresh": Refresh the status of all files. - "Upload": Jump to the menu for uploading new data.',
        deleteDownloadTitle: 'Delete, Download',
        deleteDownloadMsg: '"Delete": Delete the data of this file from the toolbox. - "Download": Have the original file that was used for the upload sent to you by e-mail.',
        statusTitle: 'Status of the file',
        statusMsg: 'The status of the file is displayed. If the status does not update itself, you can update it manually by clicking the button in the upper right corner. The following status can occur: "In preparation": The data is being uploaded. A loading bar indicates the progress. - " Predicting": Based on the new data, the commodity group predictions and harmonizations are adjusted. - "Deleting": The data is removed from the toolbox. - "Ready with AI predictions": The file has been uploaded successfully. - "Aborted": an error occurred during upload. In this case, the support support@kiresult.com will be happy to help.',
      },
      lutOverview: {
        deleteDownloadTitle: 'Delete',
        deleteDownloadMsg: 'Delete the data of this LUT from the toolbox.',
      },
      categoryLab: {
        dashboard: {
          startTitle: 'Start the CategoryLab',
          startMsg: 'Start the CategoryLab with all filters that are currently set in the filter menu. Starting the CategoryLab takes a few seconds depending on the amount of data. The CategoryLab shows you a summary of the transactions in the toolbox. On this basis, commodity group assignments can be added.',
          updateTitle: 'Update all transactions',
          updateMsg: 'Update all transactions with the latest changes in CategoryLab. The process may take a few minutes depending on the amount of data. During the update, the toolbox can be used without restrictions. Also, the logout can be used or the browser window can be closed.',
          optionsTitle: 'Further options for the CategoryLab',
          optionsMsg: 'Select from further filters to be applied to your CategoryLab session. Note that if you make changes, the filters will only display the new status after the transactions have been updated.',
          performanceTitle: 'Visualization of the commodity group assignment',
          performanceMsg: 'Selected filters are applied to all visualizations. For explanations of the visualizations, move the mouse pointer over the info icon.',
        },
        table: {
          modelStatusTitle: 'Status',
          modelStatusMsg: 'The status of the AI is displayed. The status must always be "AI ready".',
          expandFilterTitle: 'Further filter',
          expandFilterMsg: '',
          chartProbabilityTitle: 'Accuracy',
          chartProbabilityMsg: 'The accuracy of the predicted commodity groups is displayed. By clicking on a group in the chart, a corresponding filter is set.',
          processedTransactionsTitle: '',
          processedTransactionsMsg: 'Number of transactions behind this CategoryLab session classified by a manual rule.',
          processedSpendTitle: '',
          processedSpendMsg: 'Spend of the transactions behind this CategoryLab session. If you jumped to the CategoryLab with filters, this spend is not equal to the total spend in the toolbox. Even if jumping to the CategoryLab was done without filters, the value may be lower because tail spend is not considered for better performance. Tail spend can be explicitly addressed by setting appropriate filters before starting CategoryLab.',
          chartCategorySharesTitle: '',
          chartCategorySharesMsg: 'The displayed commodity group structure reflects only the current CategoryLab session and may differ from the commodity group structure in the entire toolbox. Clicking on a commodity group sets a corresponding filter.',
          filterColumnContentLabel: '',
          filterColumnContentMsg: 'Transactions that contain a similar keyword are selected and displayed sorted by similarity. All features displayed in the CategoryLab are searched. An exception is the commodity group, for which a separate filter field exists.',
          filterChosenCategoryTitle: '',
          filterChosenCategoryMsg: 'For the selection of a commodity group the commodity group number can be specified. A search by name is not possible. For commodity groups of the highest hierarchy, the element can also be selected in the chart of the commodity group structure (top right).',
          filterLanguageTitle: '',
          filterLanguageMsg: 'When jumping into the CategoryLab, a language is assigned to each transaction. For each language, a dedicated trained AI is addressed.',
        },
        categoryToolbar: {
          optionsTitle: 'Further options',
          optionsMsg: 'Show further options for the commodity group search.',
          onlyAITitle: '',
          onlyAIMsg: 'Show only the commodity groups that are considered likely by the AI.',
        },
      },
      rules: {
        searchFieldTitle: 'Search for a rule',
        searchFieldMsg: 'Search for a specific rule. The search can be limited using the other filters. In case the entered name does not match exactly, the most probable matches are displayed at the top of the list.',
        ruleTypeFieldTitle: 'Choose the type of rule',
        ruleTypeFieldMsg: 'There are different types of rules. "Manual": Rules that are created directly in the rule list. - "CategoryLab": Rules that are created via the CategoryLab. - "Vendor DB": Rules from the kiresult database for vendors. These rules cannot be deleted or customized.',
      },
      cluster: {
        typeFieldTitle: 'Choose the type of cluster',
        typeFieldMsg: 'Depending on the setting, not only the vendor is harmonized, but also other attributes. In this case, the dropdown can be used to select the different attributes.',
        clusterContentTitle: 'Content of the cluster',
        clusterContentMsg: '',
        clusterTitle: 'Name of the cluster',
        clusterMsg: '',
      },
      tabs: {
        headerTitle: '',
        headerMsg: 'Additional visualizations are displayed in the tabs. The filters set in the filter menu are applied.',
      },
      transactions: {
        searchTitle: 'Search',
        searchMsg: 'The entire database can be searched using the text search. To do so, simply enter several keywords separated by spaces and confirm. The displayed transactions are sorted by similarity wth the specified keywords. The text search can be combined with the filters in the filter menud. Attributes that are not covered by the text search can first be set in the filter menu.',
        selectTitle: 'Configuration of columns',
        selectMsg: 'Select only the columns that are of interest. This way the information will be displayed more compact on the screen.',
      },
      pivot: {
        tableTitle: 'Pivot table',
        tableMsg: 'Drag the features into the columns or the rows to create a pivot table. The order of the features in the row or column determines the structure of the pivot table and can be adjusted at any time. The pivot table can be combined with the filter menu to limit the data in advance. This allows a better overview of features with many different attributes.',
      },
    },
  },
  lut: {
    statusMsg: 'Look-Up Tables are applied',
    successMsg: 'Look-Up Tables have been successfully applied',
    title: 'Look-Up Table list',
    searchFieldTitle: 'Search',
    delete: {
      title: 'Delete LUT',
      msg: 'Are you sure you want to delete this LUT?',
      msgConfirm: 'Are you absolutely sure?',
      msgAskForLUTName: 'This change cannot be undone. Please enter the LUT name and click "Yes" again.',
      insertLUTName: 'LUT name',
    },
    create: {
      createTitle: 'Create LUT',
      nameFieldTitle: 'Set name for the LUT',
      nameFieldPlaceholder: 'name',
      nameConflictError: 'Current name is already taken',
      keyFieldsInputTitle: 'Set key fields for the LUT',
      keyFieldsInputPlaceholder: 'key field name',
      valueFieldInputPlaceholder: 'value field name',
      createButtonSubmit: 'Create LUT',
      addKeyFieldButton: 'Add Key Field',
      fileUploadPlaceholder: 'LUT File',
      fileUploadMapping: 'Column for '
    },
    update: {
      priority: 'Change the order in which the LUTs are applied. The lower tables will be applied first and the upper tables last. If the priority is the same, the order can be random.',
    },
    preview: {
      title: ' - Rows',
      details: 'Details',
      status: {
        OUTDATED: 'OUTDATED',
        OUTDATED_details: 'The values of this table have been changed. Update all tables to apply the change.',
        UPLOADING: 'UPLOADING',
        PENDING: 'PENDING',
        PROCESSING: 'PROCESSING',
        DELETING: 'DELETING',
        DEACTIVATED: 'DEACTIVATED',
        DATA_LOSS: 'DATA LOSS',
        DATA_LOSS_details: 'This table may overwrite other data and has been disabled. Contact support support@kiresult.com for help.',
      },
      target_type: {
        TRANSACTION: 'transactions',
        PROJECT_SAVINGS_POSITION: 'savings positions',
        PROJECT: 'projects',
        ALL: 'all',
      },
      header: {
        name: 'name',
        key_fields: 'key fields',
        value_field: 'target field',
        actions: 'actions',
        user: 'user',
        created_at: 'created at',
        created_by: 'created by',
        updated_at: 'updated at',
        priority: 'priority',
      },
      download: 'Download LUT',
      createRowButton: 'Add Row',
      createRowTitle: 'Create LUT Row',
      createRowButtonSubmit: 'Create Row',
      keyFieldTitle: 'Insert all key fields',
      valueFieldTitle: 'Insert all value fields',
      updateRowTitle: 'Update LUT Row',
      updateRowButtonSubmit: 'Update Row',
      searchFieldTitle: 'Search for LUT row',
    }
  },
  file: {
    title: 'All files',
    download: 'Download file',
    delete: {
      title: 'Delete file',
      msg: 'Are you sure you want to delete this file?',
      msgConfirm: 'Are you absolutely sure?',
      msgAskForFilename: 'This change cannot be undone. Please enter the file name and click "Yes" again.',
      filename: 'File name:',
    },
    upload: {
      title: 'File upload',
      file: 'Selected file',
      attributes: 'Available features',
      selectedAttributes: 'Selected features',
      selectedAttributesPlaceholder: 'Assign feature...',
      dropAttributes: 'Features move here',
      window: 'Drag file in or click to upload',
      submit: 'Submit',
      preview: 'Preview of data structure',
      previewColumn: 'selected Column',
      error: 'Unfortunately there was an error uploading, please try again.',
      success: 'Upload successful',
      back: 'Return to overview',
      validation: {
        pending: 'The selected features are validated. Please be patient for a moment.',
        error: 'The selected features still have errors. Please check the assignment.',
      },
      status: {
        pending: {
          header: 'Pending',
          description: 'The file is being created by a user.',
        },
        initializing: {
          header: 'In preparation',
          description: 'The file is preprocessed by kiresult, no actions are possible. In the meantime no other file can be deleted or uploaded.',
        },
        predicting: {
          header: 'Predictions are created',
          description: 'The AI model processes the data and creates predictions for commodity groups. In the meantime no other file can be deleted or uploaded.',
        },
        ready: {
          header: 'Ready with AI predictions',
          description: 'The file is fully prepared, including AI predictions.',
        },
        deleting: {
          header: 'Will be deleted',
          description: 'The file is being deleted. In the meantime no other file can be deleted or uploaded.',
        },
        failed: {
          header: 'Failed',
          description: 'An error occurred during the upload.',
        },
      },
    },
  },
  facts: {
    types: {
      EMPLOYEE: 'Employees in procurement',
      BUDGET: 'Budget',
      REVENUE: 'Revenue',
      PRICE_TARGET: 'Target price',
    },
    information: 'Information',
    subvalue: 'Dimension',
    subtype: 'Dimension attribut',
    action: 'Action',
    title: 'Company informations',
    deleteConfirmation: 'Are you sure you want to delete this information?',
    edit: 'Edit information?',
    update: 'Update',
    create: 'Add information',
    type: 'Type of information',
    dimensionAttribute: 'Dimension attribute',
    value: 'Value',
    year: 'Year',
    add: 'Add',
    loading: 'Loading',
    noResults: 'No results found',
    dimension: 'Dimension',
    sucessInformationCreated: 'Information created',
    sucessInformationEdited: 'Information updated',
  },
  pivot: {
    resetButtonLabel: "Reset pivot table",
    warning: "Use as many filters in the filter menu (above) as possible to improve the performance of pivot tables.",
    attributes: 'Available features',
    rows: 'rows',
    cols: 'columns',
    hideSettings: 'Hide settings',
    showSettings: 'Show settings',
    noData: 'Please drag features to either the columns or rows.',
    timeFrameYears: 'Time period: Years',
    timeFrameMonths: 'Time period: Months',
    timeFrameWeeks: 'Time period: Weeks',
    categoryLevel: 'Category level',
  },
  download: {
    success: 'The exported file will be sent to you by e-mail.',
  },
  settings: {
    save: "Save",
    organization: {
      header: "Organization",
      name: "Name",
      modules: "Modules",
      timerange: "Time period",
      userquota: "User quota",
      transactionquota: "Transaction quota",
      apiquota: "API quota",
    },
    language: {
      header: "Edit language settings (for transactions)",
      label: "Set languages",
      success: "Languages successfully set",
    },
    cluster: {
      header: "Edit harmonization settings",
      label: "Set cluster",
      success: "Clusters successfully set",
    },
    download: {
      header: "Edit download",
      label: "Set columns",
      success: "Columns successfully set",
    },
    aggregation: {
      header: "Edit category aggregation",
      label: "Set attributes",
      success: "Attributes successfully set",
    },
    fileTransfer: {
      header: "Secure upload to the kiresult server",
    },
    etl: {
      title: "Settings of the API",
      SAVINGS_PROJECTS: "Savings",
      time_status: "Last updated on",
      api_auth: "Authentication",
      api: "Data",
      success: "API settings saved",
    },
    form_rules: {
      required: "Required",
      no_comma: "Comma can lead to errros",
    },
  },
  profile: {
    headline: "User profile",
    name: "name",
    email: "e-mail",
    edit: "Edit",
    changePassword: "Change password",
    editUserProfile: "Edit user profile",
    cancel: "Cancel",
    reset: "Reset",
    save: "Save",
  },
  changePassword: {
    headline: "Change password",
    user: "User",
    password: "Password",
    passwordConfirm: "Confirm password",
    passwordHasToBeSet: "Password can not be empty",
    passwordMustContainFigure: "Password must contain at least one figure",
    passwordLengthError: "The password must have more than 12 characters",
    passwordMustBeIdentical: "Passwords must be identical",
  },
  cluster: {
    statusMsg: 'Harmonization is applied',
    successMsg: "Harmonization successfully applied",
    updateInfo: "Update all harmonizations. This process may take a few minutes.",
    collectionFilterLabel: "Type of cluster",
    headline: "Harmonization - Cluster",
    header: {
      cluster: "cluster",
      objects: "objects",
    },
    rules: {
      createInfo: "Create new cluster rule",
      noName: "AUTO (ki)",
      headline: "Harmonization - Rules",
      ruleTypeFilterLabel: "Type of Rule",
      header: {
        rule_name: "target name",
        rule: "objects",
        updated_at: "updated at",
        user: "created by",
        actions: "actions",
      },
      searchFilterLabel: "Search for rules",
      form: {
        info: "To apply the new rules, update the data using the button in the top right-hand corner.",
        createTitle: "Create cluster rule",
        editTitle: "Update cluster rule",
        ruleName: "target name",
        ruleNameHint: "name of the cluster to be used in the feature.",
        collection: "type of cluster",
        ruleType: "type of rule",
        ruleTypeHint: "combine (ADD) or separate (SEPARATE) objects into a cluster.",
        ruleHint: "All objects in the list will in future be combined into a cluster (rule type: ADD) or removed from a cluster (rule type: SEPARATE).",
        addRuleItem: "name of the object",
      },
      deleteForm: {
        title: "Delete cluster rule",
        description: "Are you sure you want to delete this rule? Enter the target name (name of the cluster) to confirm.",
        typeNameToDelete: "Insert the target name",
      },
    },
    performance: {
      headline: "Harmonization - Overview",
      performanceStats: {
        header: "Performance of harmonization",
        info: 'The overview shows the number of objects, the number of successful harmonisations (number of clusters) and the duplicates resolved.',
        objectsCount: "Number of objects",
        clustersCount: "Number of clusters found",
        duplicatesCount: "Number of duplicates resolved",
      },
      ruleStats: {
        header: "Rules",
        info: "Number of all rules that were created to further harmonise (merge) or separate objects if the automatic harmonisation was incorrect.",
        rulesADD: "Rules for adding",
        rulesSEPARATE: "Regeln for separating",
      },
    },
  },
  dashboard: {
    categoryLab: {
      title: 'Data quality',
      subtitle: 'Detailed mapping of your spend data to category groups by our AI.',
      linkCategoryLab: 'Go to CategoryLab',
      linkCategoryLabRules: 'Rules for CategoryLab',
      numberRules: '# Rules',
      shareCategorizerSpend: 'Classified spend',
    },
    cluster: {
      linkCluster: 'Harmonization of your vendors',
    },
    analtyics: {
      subtitle: 'Visualization and detailed analysis of your data.',
      totalSpend: 'Total spend',
      numberVendors: '# vendors',
      linkDashboard: 'Dashboard',
      linkCategoryDetails: 'Detailed analysis of your categories',
      linkVendorDetails: 'Detailed analysis of your vendors',
      linkPivot: 'Pivot table',
    },
    insights: {
      subtitle: 'Automated data interpretation and measure derivation.',
      caseSubtitle: 'What does this mean to my company?',
      ebit: 'EBIT in year 2019',
      ebitPotential: 'Potential for EBIT-increase of 44%',
    },
    documentation: {
      title: 'Documentation & FAQ',
      subtitle: 'Video tours through your kiresult platform. Get to know all functionalities in detail.',
      link: 'Go to documentation',
      linkOverviewVideo: 'Overview of your kiresult Toolbox',
      linkGlossary: 'Glossary',
    },
    views: {
      title: 'Saved views',
      subtitle: 'Choose from your last saved views.',
      link: 'Edit your views',
      countLocal: 'Own views',
      countGlobal: 'Global views',
    },
    kpi: {
      title: 'Procurement controlling',
      subtitle: 'Overview of the performance of your KPIs.',
      linkKPIDashboard: 'Go to KPI-Dashboard',
      linkFacts: 'Your company informations',
      countSuccess: 'Target reached',
      countBetween: 'Near target',
      countFailed: 'Target missed',
    },
    module: {
      mostImportantKPI: 'Your most important KPI',
      toKPIDashboard: 'to the KPI-Dashboard',
      top5SpendDistribution: 'TOP5 spend distribution',
      toSpendAnalyticsTool: 'to the Spend-Analytics tool',
      savedViews: 'Saved views',
      fastLinks: 'Fast access',
    },
  },
  categoryLab: {
    rule: {
      rule: 'rule',
      header: 'CategoryLab - Rules',
      schemaFilterLabel: 'Features',
      searchFilterLabel: 'Search for rules',
      typeFilterLabel: 'Rule type',
      add: 'Add',
      table: {
        header: {
          rule: 'Rule',
          feature: 'Feature',
          category: 'Category',
          created_at: 'Created at',
          created_by: 'Created by',
          status: 'Status',
          actions: 'Actions',
        },
      },
      ki: 'This rule was created automatically and cannot be changed.',
    },
    model: {
      title: 'Commodity group classification (text)',
      formLabel: 'Enter text',
      formPlaceholder: 'Enter a text to get a commodity group classification',
      formButton: 'Predict',
      formResult: 'Commmodity group',
    },
    loadingStatus: {
      step1: 'Get the relevant data from your database...',
      step2: 'Summarize the data so you have less to look at...',
      step3: 'Apply your custom AI to the data...',
      step4: 'Calculate dashboards for easy overview. Soon ready...',
    },
    dashboard: {
      subtitle: 'Define the criteria with which you want to jump into the CategoryLab.',
      applyChanges: 'Apply changes of the CategoryLab',
      timeFilterText: 'Select time period',
      dataExtracts: 'Data extracts',
      performance: 'Performance of classification',
      comparison: 'Comparison of types of classification',
      deviationDetails: 'Deviating categories in detail',
      depth: 'Depth of assignement',
      stats: 'Statistics',
      share: 'Share with categorization',
      consistency: 'Consistency of assignment',
      advanced: 'Extended views',
      start: 'Start CategoryLab',
      options: 'Expand CategoryLab configuration. Note that if you make changes in the CategoryLab, the filters will only display the new status after the transactions have been updated.',
      predict: 'Update transactions',
      predictLastTime: 'Last',
      furtherPerformance: {
        header: 'Further performance',
      },
      rules: {
        header: 'Rules',
        stats: 'Number of rules',
        depth: 'Depth of rules',
        ambiguity: 'Ambiguous rules',
      },
      schema: {
        info: 'For a quick but broad assignment, only one or two features should be selected (as vendor and/or general ledger). For a deep and detailed categorization, up to 4 features including some description are useful. The features can be reselected anytime.',
      },
      optionFilterOnConflicts: 'Only rows with conflicts',
      optionFilterOnNoPredictions: 'Only rows without CategoryLab predictions',
    },
    performance: {
      stats: {
        info: 'Share of classified spend and transactions.',
        spend: 'spend',
        spend_share: 'of the spend',
        transactions: 'transactions',
        transaction_share: 'of the transactions',
      },
      depth: {
        info: 'Depth of the classified spend per category. On the left side, the product groups are listed according to their relative share of the spend (%). For each category the depth of classification (e.g. level 1 to level 4) is shown horizontally. For each level, the classified share of spend (%) is indicated. Deep classifications can be recognized by contrasting colors on the right side. The higher they are, the more spend is affected. If "transactions" is selected as the value in the filter menu, all relative data will refer to the number of transactions instead of the spend.',
      },
      depthComparison: {
        info: 'Comparison of the depth of the classified spend between the available category displays. The height of each column represents the total share of the classified spend.The composition of the column shows how the total share is distributed among the different levels in the category tree. If "transactions" is selected as the value in the filter menu, all relative data will refer to the number of transactions instead of the spend.',
      },
      consistency: {
        info: 'Comparison of the match between the available category displays. For each display option, the match is compared with all other display options.  A match is defined by the same or a lower classification. No match corresponds to a completely different assignment in the category tree.',
      },
      ruleStats: {
        info: 'Number of created rules.',
        rules: 'rules',
      },
      ruleDepth: {
        info: 'Proportion of rules per hierarchy in the category tree.',
      },
      ruleAmbiguity: {
        info: 'The number of rules that affect 1, 2, 3 or more than 4 level 1 categories.',
      },
    },
    instructions: {
      headline: 'Video explanation of the CategoryLab',
      text: 'Here you can find out how the CategoryLab works and what options are available.',
      ok: 'To the tool',
    },
    table: {
      labLoading: 'Please be patient for one moment. Your individual CategoryLab is being prepared.',
      labLoadingExplanation1: 'CategoryLab displays a category prediction for each transaction (purchase order or invoice).',
      labLoadingExplanation2: 'This assignment can be confirmed, verified or lowered based on the available information.',
      filterColumnContent: 'Filter by column content',
      filterChosenCategory: 'Filter by chosen category',
      filterLanguages: 'Filter by language',
      onlyShowEmptyCategories: 'Only show empty category allocations',
      doNotShowRowsWithRules: 'Hide rows with chosen rules',
      onlyShowRowsWithRules: 'Only show rows with chosen rules',
      removeAllFilters: 'Remove all filters',
      hideFilterMenu: 'Hide filter menu',
      ai: 'ai',
      changeAllocation: 'Change allocation',
      submitThisAllocation: 'Submit this allocation',
      pleaseChoose: 'Please choose',
      chooseAllocation: 'Choose allocation',
      resetRow: 'Reset row',
      resetRowSuccess: 'Row successfully reset',
      chooseCategory: 'Choose allocation',
      selectAttributeFitting: 'Please select a feature which fits to a category first',
      selectRow: 'Assign all transactions related to this line to a commodity group.',
      selectRowWarning: 'Rules can not be combined with the direct assignment of transactions.',
      lostConnection: 'Unfortunately, the connection to the kiresult server was interrupted. Please reload the page to ensure functionality.',
      furtherFilter: 'Further filter',
      newRule: 'New rule',
      existingRulesFor: 'Existing rules for',
    },
    topDashboard: {
      autoRefresh: 'Auto-Refresh',
      rowsWithRules: 'Rows with rules',
      totalSpend: 'Total spend',
      totalTransactions: 'Total transactions',
      ofSpendAllocated: 'of spend allocated',
      ofTransactionsAllocated: 'of transactions allocated',
      aiReady: 'AI ready',
      aiWorking: 'AI training',
      aiLoading: 'AI loading',
    },
    sidebar: {
      categorySupport: 'Category support',
      onlyAI: 'only AI',
      search: 'Search',
      searchWord: 'Search for',
      removeSelection: 'Remove selection',
      noCategorySelected: 'No category selected',
      selectCategoryFirst: 'Please select a category first',
      activeRule: 'Edited rule',
      headerCategory: 'category',
      headerSpend: 'spend',
    },
    hierarchyDialog: {
      headline: 'Please select if the attributes are assigned to the correct category levels.',
      column: 'Column',
      dragHere: 'Drag attributes here',
      notUsedElements: 'Not used attributes',
      allocationDiffers: 'Allocation differs',
      dragHereToDelete: 'Drag elements here to delete',
      similarRules: 'Found similar attributes',
    },
    valueTypeWarning: 'The performance of the classification is determined only on the basis of spend and not transactions.',
    predictions: {
      successMsg: 'The data from the CategoryLab was transferred.',
      statusMsg: 'Predictions are transferred.',
      statusSlowMsg: 'It takes a little longer than expected.',
    },
    settings: {
      schema: {
        header: 'CategoryLab scheme configuration',
        attributes: 'Available features',
        selectedAttributes: 'Selected features',
        selectedAttributesPlaceholder: 'Assign feature...',
        dropAttributes: 'Features move here',
      },
    },
    contextMenu: {
      copySingle: 'Copy (single word)',
      googleSingle: 'Google research (single word)',
      copyWhole: 'Copy (whole text)',
      googleWhole: 'Google research (whole text)',
    },
  },
  analytics: {
    common: {
      noDataAvailable: 'No data available',
      invoices: 'Invoices',
      orders: 'Orders',
      compareChip: 'Compared to Ø unit price of',
    },
    timeSelection: {
      Gesamt: 'Total',
      YTD: 'YTD',
      Jahr: 'Year',
      Quartal: 'Quarter',
      Eigen: 'Custom',
      to: 'to',
    },
    timeSelectionCompare: {
      Keinen: 'None',
      'YTD vor einem Jahr': 'YTD last year',
      'YTD im Jahr': 'YTD in year...',
      'Jahr davor': 'Previous year',
      'Jahr': 'Year...',
      'Selbes im Vorjahr': 'Same in previous year',
      'Quartal': 'Quarter...',
      'Selber letztes Jahr': 'Same in previous year',
      'Selber im Jahr': 'Same in year...',
    },
    filterPane: {
      dashboard: 'Dashboard',
      categoryLab: 'CategoryLab',
      vendorDetails: 'Vendors',
      categoryDetails: 'Categories',
      spendDetails: 'Spend Analytics',
      savingsDetails: 'Savings',
      insightsDetails: 'Spend Insights',
      unitDetails: 'Unit Prices',
      actionsDetails: 'Procurement Actions',
      transactionDetails: 'Transactions',
      pivot: 'Pivot Table',
      kpiDetails: 'KPI-Dashboard',
      reportDetails: 'Reports',
      timePeriod: 'Time period',
      choose: 'Choose',
      chooseTimePeriod: 'Choose time period',
      comparePeriod: 'Compare period',
      chooseCompareTimePeriod: 'Choose compare time period',
      category: 'category',
      categories: 'Categories',
      loading: 'Loading...',
      foundNoResults: 'Found no results',
      listNotComplete: 'not complete',
      onlyTopElementsAreShown: 'Only a few elements are shown.',
      onlyTopElementsAreShownSearch: 'Only a few elements are shown. You can use the search function to find all elements.',
      removeAllFilters: 'Reset all filters and time period',
      showFilterMenu: 'Show filter pane',
      hideFilterMenu: 'Close filter pane',
      furtherFilters: 'Further filter',
      showCategoryLabCategories: 'Type of Categories displayed',
      showCategoryLabCategoriesDisabled: 'CategoryLab deactivated',
      DISABLE: 'CategoryLab deactivated',
      showCategoryLabCategoriesDisabledTooltip: 'Your own category assignment from the data upload will be used.',
      showCategoryLabCategoriesTransactionBased: 'Transaction-based',
      TRANSACTION_BASED: 'Transaction-based',
      showCategoryLabCategoriesTransactionBasedTooltip: 'One category is predicted per transaction (order or invoice).',
      showCategoryLabCategoriesVendorBased: 'Vendor based',
      AGGREGATION_BASED: 'Vendor based',
      showCategoryLabCategoriesVendorBasedTooltip: 'One category is predicted per supplier.',
      showCategoryLabCategoriesJustTransactions: 'CategoryLab activated',
      showCategoryLabCategoriesJustTransactionsTooltip: 'The categories enriched by the kiresult CategoryLab are used.',
      harmonization: 'Harmonization',
      valueTypeText: 'Show values as',
      valueTypeSpend: 'spend',
      valueTypeSpendTooltip: 'The spend is shown in all visualizations and tables.',
      valueTypeTransactions: '# transactions',
      valueTypeTransactionsTooltip: 'The number of transactions is shown in all visualizations and tables.',
      harmonizationWarning: 'Harmonization can be used here only for filters.',
      allSelect: '(Select all)',
      infoAllSelectSearch: '"(Select all)” selects not only the search results below, but all filter options. The checkbox can be used to exclude individual elements found.',
      allSelected: 'Selected all',
    },
    categoryDrillDialog: {
      headline: 'Are you sure, that you only want to filter this category?',
      explaination: 'All other category filters except',
      removed: 'and filters on its lower category levels are being removed.',
    },
    chartOptions: {
      spend: 'spend',
      count: 'transactions',
      day: 'days',
      week: 'weeks',
      month: 'months',
      year: 'years',
      DAY_singular: 'day',
      WEEK_singular: 'week',
      MONTH_singular: 'month',
      YEAR_singular: 'year',
      noData: 'No data'
    },
    chartDescriptions: {
      totalSpend: 'Total spend',
      numberVendors: '# vendors',
      numberTransactions: '# transactions',
      developmentOverTime: 'Development over time',
      vendors: 'Vendors',
      vendorNumber: 'Vendor number',
    },
    bubbleCharts: {
      highRelevanceLowSpend: 'High relevance - consolidated spend',
      highTransactionsFewVendors: 'Many transactions - few vendors',
      highRelevanceHighSpend: 'High relevance - wide spread spend',
      highTransactionsManyVendors: 'Many transactions - many vendors',
      lowRelevanceLowSpend: 'Low relevance - consolidated spend',
      lowTransactionsFewVendors: 'Few transactions - few vendors',
      lowRelevanceHighSpend: 'Low relevance - wide spread spend',
      lowRelevanceManyVendors: 'Few transactions - many vendors',
    },
    tableHeaders: {
      category: 'category',
      vendor: 'vendor',
      vendorNumber: 'number',
      vendorName: 'vendor name',
      spend: 'spend',
      details: 'details',
      name: 'name',
      submit: 'submit',
      numberTransactions: '# transactions',
      spendPerTransaction: 'spend per transaction',
      classification: 'tag',
      actions: 'Actions',
      putToFilter: 'Set as filter',
      goToDetailPage: 'Jump to detail page',
    },
    dashboard: {
      hello: 'Hello',
      subtitle: 'Summary of your spend',
      availableTimeRange: 'Available time range',
    },
    transactions: {
      furtherOptions: 'Show more options',
      empty: 'No transactions found',
    },
    quantities: {
      loadQuantityInformation: 'Quantity information',
      noQuantityInformationAvailable: 'No quantity information available',
      helpForFilters: 'Please first select a material or a detailed category in the filter menu (above) in order to carry out a comparable unit price analysis. Then select an exact unit of measure.',
      helpForFiltersSmall: 'For a comparable unit price analysis, a material or a detailed category should be selected',
      units: 'Units of measure',
      unitPriceAnalysis: 'Unit price analysis',
      timeAggregation: 'Time aggregation',
      selectUnit: 'select a unit of measure',
      unit: 'Unit of measure',
      quantity: 'quantity',
      spendPerUnit: 'Spend per unit of measure',
      averageUnitPrice: 'Ø unit price',
      spend: 'spend',
      detailAnalyses: 'Detailed analysis',
      tableTimeAndGap: {
        title: 'Time development & gap analysis',
        header: {
          time: 'time period',
          unitPrice: 'unit price',
          compareTime: 'comparison period',
        },
        compareToTimeStep: 'compared to the previous step with',
        compareToComparePeriod: 'compared to the comparison period with',
      },
      tableDimensions: {
        header: {
          name: 'name',
          quantity: 'quantity',
          spend: 'spend',
          count: 'transactions',
          unitPrice: 'unit price',
        },
      },
    },
    indices: {
      sidebarHeadline: 'Indices & Cost-Benchmarking (to be released)',
      marketIndices: 'Market indices',
      suitableIndices: 'Suitable indices for this filters',
      search: 'Search...',
      source: 'Source',
      allIndices: 'All indices',
    },
    components: {
      shownLevel: 'shown level',
      detailViewCategoryLevels: 'Detail view for category levels',
      detailReports: 'Detailed reports',
      categoryDetails: 'Category details',
      evaluationOverAllCategories: 'Evaluation across all categories',
      vendorOverview: 'Vendor overview',
      detailAnalyticsVendorsInCategories: 'Detailed analysis of supplier structure within categories',
      ofSpend: 'of spend',
      with: 'with',
      vendors: 'vendors',
      vendorDetails: 'Vendor details',
      evaluationOverAllVendors: 'Evaluation across all vendors',
      searchForVendors: 'Search for vendors...',
      resultsFor: 'Your results for',
      abcOverview: 'ABC overview',
      allVendors: 'All vendors',
      transactionOverview: 'transaction overview',
    },
  },
  insights: {
    betaWarning: 'The initiatives and data shown here are a preview version on demo data. They are therefore not yet adapted to your own company.',
  },
  documentation: {
    'headline': 'Documentation & FAQ',
    'headlineVideoTours': 'Video-tours through the kiresult platform. At the moment, these tours are only available in German.',
  },
  report: {
    transactions: {
      standardTitle: 'Standard Reports (Transactions)',
      standardDescription: 'Export individual transactions. All filters set above are applied before the download.',
      excel: {
        title: 'Excel-Export',
        description: 'Export individual transactions. All filters set above are applied before the download.',
      },
      csv: {
        title: 'CSV-Export',
        description: 'Export individual transactions. All filters set above are applied before the download. The CSV delimiter is a semicolon.',
      },
      json: {
        title: 'JSON-Export',
        description: 'Export individual transactions. All filters set above are applied before the download.',
      },
    },
    actionLog: {
      standardTitle: 'Standard Reports (ActionLog)',
      standardDescription: 'Export all ActionLog projects. Filters are not applied.',
      excelWithSavings: {
        title: 'Savings',
        description: 'Export all ActionLog projects with savings positions.',
      },
      excelWithBenefits: {
        title: 'Non-financial benefits',
        description: 'Export all ActionLog projects with non-financial benefits.',
      },
    },
    download: 'Download',
    downloadSuccess: 'The download of the report has been initiated. The report will be sent to you by e-mail.',
    customTitle: 'Individual Reports',
    customNoReports: 'No individual reports available',
  },
  enums: {
    INVOICE: 'order',
    ORDER: 'invoice',
    true: 'yes',
    false: 'no',
    SPEND: 'spend',
    COUNT: '# transactions',
    category: {
      'CUSTOMER': 'CategoryLab deactivated',
      'BEST': 'CategoryLab activated',
      'BEST_AGGREGATED': 'Vendor based',
    },
  },
  dataStructure: {
    'identifier': 'identifier (ki)',
    'order_identifier': 'order-/invoice number',
    'order_position': 'order-/invoice position',
    'order_code': 'order-/invoice code',
    'order_type': 'order-/record-type',
    'order_or_invoice': 'Order or Invoice',
    'invoice_identifier': 'invoice number',
    'order_reference': 'order reference',
    'has_order_reference': 'order reference available?',
    'intercompany': 'intercompany',
    'addressable': 'addressable spend?',
    'operative': 'operative spend?',
    'touched': 'touched spend?',
    'annex_identifier': 'annex identifier',
    'timestamp': 'order-/invoice date',
    'timestamp_year': {
      caption: 'Year',
      hierarchy: 'order-/invoice date'
    },
    'timestamp_month': {
      caption: 'Month',
      hierarchy: 'order-/invoice date',
      parent: 'timestamp_year'
    },
    'timestamp_day': {
      caption: 'Day',
      hierarchy: 'order-/invoice date',
      parent: 'timestamp_month'
    },
    'timestamp_order': 'order date',
    'timestamp_invoice': 'invoice date',
    'timestamp_invoice_due_date': 'invoice due date',
    'timestamp_posting': 'posting date',
    'timestamp_payment': 'payment date',
    'timestamp_delivery': 'delivery date',
    'timestamp_delivery_due_date': 'delivery due date',
    'timestamp_running_time_start': 'performance period start',
    'timestamp_running_time_end': 'performance period end',
    'time_invoice_to_invoice_due_date': 'time from invoice to due date',
    'time_invoice_to_posting': 'time from invoice to posting',
    'payment_on_time': 'payment on time?',
    'delivery_on_time': 'delivery on time?',
    'spend': 'spend (net)',
    'spend_average': 'average spend',
    'count': 'count',
    'currency': 'currency',
    'cluster_vendor_id': 'cluster vendor id',
    'category_predicted_parents_ids': 'category predicted parents ids',
    'category_best_parents_ids': 'category best parents ids',
    'category_best_id': 'category',
    'category_hier_1': 'category lvl. 1',
    'category_hier_2': 'category lvl. 2',
    'category_hier_3': 'category lvl. 3',
    'category_hier_4': 'category lvl. 4',
    'category_parents_ids': 'category parents ids',
    'category_best_by_aggregation_id': 'category best by aggregation id',
    'options': 'options',
    'category_best_by_aggregation_parents_ids': 'category best by aggregation parents ids',
    'column_attributes_for_prediction_text': 'column attributes for prediction text',
    'column_attributes_for_prediction_category': 'column attributes for prediction category',
    'spend_per_quantity': 'spend per quantity',
    'quantity': 'quantity',
    'quantity_supplied': 'quantity supplied',
    'quantity_invoiced': 'quantity invoiced',
    'quantity_unit': 'quantity unit',
    'weight': 'weight',
    'tax_rate': 'tax rate',
    'terms_of_payment': 'terms of payment',
    'skonto_amount': 'skonto amount',
    'skonto_rate': 'skonto rate in %',
    'has_skonto': 'skonto available?',
    'skonto_taken': 'skonto taken?',
    'factory': 'factory',
    'direct_indirect': 'direct/indirect',
    'incoterm': 'incoterm',
    'description': 'description',
    'description_short': 'description (short)',
    'description_long': 'description (long)',
    'category_manager': 'category manager',
    'purchasing_group': 'purchasing group',
    'purchasing_organization': 'purchasing organization',
    'business_unit': 'business unit',
    'business_unit_board': 'responsible',
    'monitoring_regulation': 'monitoring regulation',
    'monitoring_regulation_hier2': 'monitoring regulation lvl. 2',
    'organization': 'organization',
    'period_accrual': 'period accrual',
    'vendor_general_ledger': 'vendor | general ledger',
    'country_vendor': 'vendor country',
    'country_of_delivery': 'country of delivery',
    'type_of_material': 'type of material',
    'country_company_code': 'company code country',
    'corporate_group': 'corporate',
    'status': 'status',
    'requester': 'requester',
    'responsible': 'responsible purchaser',
    'category_secondary_name': 'internal category',
    'has_category': 'customer category available?',
    'country': 'country identifier',
    'line_number': 'line number',
    'capital_operating_expenses': 'capex/opex',
    'cost_type': 'cost type',
    'lang': 'transaction language',
    'file_ids': 'data extract',
    'type': 'transaction type',
    'posting_key': 'posting key',
    'posting_period': 'posting period',
    'cp': 'CP area',
    'cp_raw': 'CP area (raw)',
    'segment': 'segment',
    'segment___huegli': 'category cluster',
    'contract': 'contract',
    'assignment': 'assignment',
    'relevant_strategic': 'Strategic relevance',
    'via_eprocurement': 'via eProcurement?',
    'via_catalog': 'via catalog?',
    'has_contract': 'contract reference?',
    'delivery_condition': 'delivery condition',
    'scope_poc': 'scope proof of concept',
    'name': 'project name',
    'non_financial_benefits': 'non-financial benefits',
    'non_financial_benefits_description': 'non-financial benefits description',
    'savings_missed_reason': 'savings missed reason',
    'vendor_id': 'vendor',
    'intercompany': 'intercompany',
    'category_ki_tag': 'category tag',
    'responsible_email': 'responsible e-mail',
    'tariff_number': 'tariff number',
    'gtin': 'GTIN',
    'cas_number': 'CAS number',
    'wbs_element': 'WBS element',
    'system': 'System',
    'hid_number': 'HID-Nummer',
    'project_type': 'project type',
    'project_type_dropdown': {
      '--': '--',
      'GENERAL': 'General',
      'NEGOTIATION': 'Negotiation',
      'TENDER': 'Tender',
      'STRATEGY': 'Strategy development',
      'INTERNAL_OPTIMIZATION': 'Internal optimization project',
      'OTHER': 'Others',
      'OTHER___utdi': 'Maverick Buying',
    },
    'user_id': 'project created by',
    'responsible_user_id': 'responsible for project',
    'member_users_ids': 'team members',
    'priority': 'priority',
    'priority_dropdown': {
      '--': '--',
      'LOW': 'Low',
      'MEDIUM': 'Medium',
      'HIGH': 'High',
    },
    'savings_position_type': 'savings type',
    'savings_position_type_dropdown': {
      '--': '--',
      'SAVINGS': 'Standard Savings',
      'SAVINGS_LIGHT': 'Savings-Lite',
    },
    'shirt_size': 'Project size',
    'placeholder_1': 'custom field 1',
    'placeholder_1___huegli': 'BIO',
    'placeholder_2': 'custom field 2',
    'placeholder_3': 'custom field 3',
    'placeholder_4': 'custom field 4',
    'placeholder_5': 'custom field 5',
    'placeholder_6': 'custom field 6',
    'placeholder_7': 'custom field 7',
    'placeholder_8': 'custom field 8',
    'placeholder_9': 'custom field 9',
    'vendor': {
      'name': 'vendor name',
      'identifier': 'vendor number',
      'country': 'vendor country',
      'city': 'vendor city',
      'zip_code': 'vendor zip code',
    },
    'category_tag': 'context (beta)',
    'category_id': 'category',
    'category': {
      'name': 'category name',
      'identifier': 'category number',
    },
    'general_ledger_id': 'general ledger',
    'general_ledger': {
      'name': 'general ledger name',
      'identifier': 'general ledger number',

    },
    'profit_center_id': 'profit center',
    'profit_center': {
      'name': 'profit center name',
      'identifier': 'profit center number',
    },
    'company_code_id': 'company code',
    'company_code': {
      'name': 'company code name',
      'identifier': 'company code number',
    },
    'material_id': 'material',
    'material': {
      'name': 'material name',
      'identifier': 'material number',
    },
    'cost_center_id': 'cost center ',
    'cost_center': {
      'name': 'cost center name',
      'identifier': 'cost center number',
    },
    'cost_unit_id': 'cost unit',
    'cost_unit': {
      'name': 'cost unit name',
      'identifier': 'cost unit number',
    },

    'processOverview': 'process insights',
  },
  collection: {
    'vendor': 'vendor',
    'general_ledger': 'general ledger',
    'material': 'material',
    'cost_center': 'cost center',
    'cost_unit': 'cost unit',
    'company_code': 'company code',
    'profit_center': 'profit center',
    'category': 'category',
    'add': {
      'number': ' number',
      'name': ' name',
    },
  },
  refresh: {
    msg: 'Refresh the page to see the latest updates.',
    button: 'Reload page',
  },
  kpi: {
    individualClusterWarning: 'For individual KPIs, only the time range can be adjusted. All other filters have no effect.',
    spendCountToggleWarning: 'The choice of values has no effect on this page',
    module: {
      timeHorizon: 'time horizon',
      messageInYearTotal: 'in total year (no time filters)',
      errorMessageImpossibleToCalculate: 'It is not possible to calculate this KPI',
      kpiValue: 'KPI value',
      per: 'per',
      targetValue: 'target value',
      benchmark: 'benchmark',
      benchmarkValue: 'benchmark value',
      target: 'target',
      toTimerangeComparison: 'to comparison time period',
      totalNumberOfBudgetExceeding: 'Total number of budget overruns',
      messageBudgetsOnlyPerYear: 'Budgets can only be displayed on a yearly level. So please select the period YTD or year.',
      messageBudgetsNotAvailable: 'No budget exists for the selected year.',
      messageBudgetsPleaseExpand: 'Please expand for more information on budgets.',
      messageBudgtesCountTotalOverBudget: 'Number of total budget overruns',
      messagePleaseChooseADimension: 'Please select a dimension to get more details',
      removeKpiAsFavorite: 'Remove this KPI from favorites',
      markKpiAsFavorite: 'Mark this KPI as favorite',
      trend: 'trend',
      messageNoTargetDefined: 'No target has been set yet for this KPI and time period',
      descriptionOfKPI: 'Description of the KPI',
      calculationOfKPI: 'Calculation of the KPI',
      showDetails: 'Show details',
      reset: 'Reset',
      detailedAnalysis: 'Detailed analysis of the KPI',
    },
    kpiDetailMenu: {
      editTargets: 'Edit targets',
      editTargetsFor: 'Edit targets for',
      showTargets: 'Show targets',
      addTarget: 'Add target',
      addTargetFor: 'Add target for',
      editTarget: 'Edit target',
      editTargetFor: 'Edit target for',
      sureToRemove: 'Are you sure you want to delete this target?',
      timeBeginTarget: 'Date of beginning of application',
      targetForSuccess: 'Target value for success',
      tresholdForFailure: 'Threshold for failure',
      refersToTimeHorizon: 'Refers to time horizon:',
      selectTime: 'Please select a date',
      add: 'Add',
      update: 'Update',
    },
    kpiDimensionTable: {
      showInTimeManner: 'Analyze this element over time',
    },
    kpiInfo: {
      kpi_transactions: {
        'name': 'Transaction count',
        'description': 'Total number of transaction lines (e.g. purchase order or invoice lines) of the filtered scope.',
        'formula': '# transactions',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_spend: {
        'name': 'Spend',
        'description': ' Sum of total expenses/costs (Invoices: Sum of all invoice amounts / Purchase orders: Sum of all purchase order items).',
        'formula': '∑ spend',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_average_spend_per_transaction: {
        'name': '⌀ Spend per transaction',
        'description': 'Average value of an order/invoice line.',
        'formula': '(∑ spend) / (∑ count transactions)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_transactions_with_order_reference: {
        'name': 'Number of transactions with order reference',
        'description': 'Number of invoice items with reference to a purchase order.',
        'formula': '∑ count transactions -> filter with order reference',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_transactions_without_order_reference: {
        'name': 'Number of transactions without order reference',
        'description': ' Number of invoice items without reference to an order (indicator for Maverick Buying).',
        'formula': '∑ count transactions -> filter without order reference',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_spend_with_order_reference: {
        'name': 'Spend with order reference',
        'description': 'Total invoiced spend with order reference.',
        'formula': '',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_spend_without_order_reference: {
        'name': 'Spend without order reference',
        'description': 'Total invoiced spend without order reference (indicator for Maverick Buying).',
        'formula': '∑ spend -> filter with order reference',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_transactions_with_order_reference: {
        'name': 'Share of transactions with order reference',
        'description': ' Relative share of invoice items with reference to a purchase order.',
        'formula': '(# transactions -> filter with order reference) / (# transactions)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_transactions_without_order_reference: {
        'name': 'Share of transactions without order reference',
        'description': 'Relative share of invoice items without reference to a purchase order.',
        'formula': '(# transactions -> filter without order reference) / (# transactions)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_spend_with_order_reference: {
        'name': 'Share of spend with order reference',
        'description': 'Relative share of invoice volume with purchase order reference.',
        'formula': '(∑ spend -> filter with order reference) / (∑ spend)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_spend_without_order_reference: {
        'name': 'Share of spend without order reference',
        'description': 'Relative share of invoice volume with purchase order reference.',
        'formula': '(∑ spend -> filter without order reference) / (∑ spend)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_transactions_with_order_reference_without_refund: {
        'name': 'Share of transactions with order reference (without refunds)',
        'description': ' Relative share of invoice items with reference to a purchase order.',
        'formula': '(# transactions -> filter with order reference) / (# transactions)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_transactions_without_order_reference_without_refund: {
        'name': 'Share of transactions without order reference (without refunds)',
        'description': 'Relative share of invoice items without reference to a purchase order.',
        'formula': '(# transactions -> filter without order reference) / (# transactions)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_spend_with_order_reference_without_refund: {
        'name': 'Share of spend with order reference (without refunds)',
        'description': 'Relative share of invoice volume with purchase order reference.',
        'formula': '(∑ spend -> filter with order reference) / (∑ spend)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_spend_without_order_reference_without_refund: {
        'name': 'Share of spend without order reference (without refunds)',
        'description': 'Relative share of invoice volume with purchase order reference.',
        'formula': '(∑ spend -> filter without order reference) / (∑ spend)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_unique_vendor: {
        'name': 'Total number of suppliers',
        'description': ' Total number of different suppliers without consideration of time filtering.',
        'formula': '# vendors without time filters -> removed duplicates',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_unique_general_ledger: {
        'name': 'Total number of G/L accounts',
        'description': ' Total number of different G/L accounts without consideration of time filtering. ',
        'formula': '# general ledgers without time filters -> removed duplicates',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_unique_factory: {
        'name': 'Total number of factories',
        'description': ' Total number of different factories without consideration of time filtering. ',
        'formula': '# factories without time filters -> removed duplicates',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_unique_purchasing_organization: {
        'name': 'Total number of purchasing organizations',
        'description': ' Total number of different purchasing organizations without consideration of time filtering. ',
        'formula': '# purchasing organizations without time filters -> removed duplicates',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_unique_purchasing_group: {
        'name': 'Total number of purchasing groups',
        'description': ' Total number of different purchasing groups without consideration of time filtering. ',
        'formula': '# purchasing groups without time filters -> removed duplicates',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_unique_category: {
        'name': 'Total number of categories',
        'description': ' Total number of different categories without consideration of time filtering.',
        'formula': '# categories without time filters -> removed duplicates',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_unique_profit_center: {
        'name': 'Total number of Profit Center',
        'description': ' Total number of different profit centers without consideration of time filtering. ',
        'formula': '# profit centers without time filters -> removed duplicates',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_unique_company_code: {
        'name': 'Total number of Chart of Accounts',
        'description': ' Total number of different Chart of Accounts without consideration of time filtering. ',
        'formula': '# company codes without time filters -> removed duplicates',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_unique_cost_center: {
        'name': 'Total number of cost centers',
        'description': ' Total number of different cost centers without consideration of time filtering. ',
        'formula': '# cost centers without time filters -> removed duplicates',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_unique_cost_unit: {
        'name': 'Total number of internal orders (cost objects)',
        'description': ' Total number of different internal oders (cost objects) without consideration of time filtering. ',
        'formula': '# cost units without time filters -> removed duplicates',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_unique_material: {
        'name': 'Total count of material numbers',
        'description': ' Total count of different material numbers without consideration of time filtering. ',
        'formula': '# materials without time filters -> removed duplicates',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_vendor_per_mio_spend: {
        'name': 'Number of active suppliers per 1 million € spend.',
        'description': 'Number of active suppliers per 1 million euros Spend. ',
        'formula': '(# vendors) / (∑ spend) * 1,000,000 ',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_vendor: {
        'name': 'Number of active suppliers',
        'description': ' Number of different suppliers that have transaction volumes in the selected period. ',
        'formula': '# vendors -> removed duplicates',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_general_ledger: {
        'name': 'Number of active G/L Accounts',
        'description': ' Number of different G/L Accounts that have transaction volumes in the selected period. ',
        'formula': '# general ledgers -> removed duplicates',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_factory: {
        'name': 'Number of active factories',
        'description': ' Number of different factories that have transaction volumes in the selected period. ',
        'formula': '# factories -> removed duplicates',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_purchasing_organization: {
        'name': 'Number of active purchasing organizations',
        'description': 'Number of different purchasing organizations that have transaction volumes in the selected period. ',
        'formula': '# purchasing organizations -> removed duplicates',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_purchasing_group: {
        'name': 'Number of active purchasing groups',
        'description': ' Number of different purchasing groups that have transaction volumes in the selected period. ',
        'formula': '# purchasing groups -> removed duplicates',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_category: {
        'name': 'Number of active categories',
        'description': ' Number of different categories that have transaction volumes in the selected period. ',
        'formula': '# categories -> removed duplicates',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_profit_center: {
        'name': 'Number of active Profit Center',
        'description': ' Number of different profit centers that have transaction volumes in the selected period. ',
        'formula': '# profit centers -> removed duplicates',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_company_code: {
        'name': 'Number of active Chart of Accounts',
        'description': ' Number of different Chart of Accounts that have transaction volumes in the selected period. ',
        'formula': '# company codes -> removed duplicates',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_cost_center: {
        'name': 'Number of Active Cost Centers',
        'description': ' Number of different Cost Centers that have transaction volumes in the selected period. ',
        'formula': '# cost centers -> removed duplicates',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_cost_unit: {
        'name': 'Number of active internal orders (cost objects)',
        'description': ' Number of different internal orders (cost objects) that have transaction volumes in the selected period. ',
        'formula': '# cost units -> removed duplicates',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_material: {
        'name': 'Count of active material numbers',
        'description': 'Number of different material numbers that have transaction volumes in the selected period. ',
        'formula': '# materials -> removed duplicates',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_count_order_reference: {
        'name': 'Count of distinct orders',
        'description': 'Number of different orders, that have transaction volume in the selected period.',
        'formula': '# order number -> Duplikate entfernt',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'requireFact': 'FALSE',
        'roundDigits': '0',
      },
      kpi_average_time_diff_invoice_to_posting: {
        'name': ' Average time from invoice to invoice posting ',
        'description': 'Average time from invoice receipt to invoice posting ',
        'formula': 'Ø (date difference invoice receiving until invoice booking)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_average_time_diff_order_to_delivery: {
        'name': ' Average time from order to delivery',
        'description': 'Average time from order to receipt of goods or service. ',
        'formula': ' Ø (date difference order date until delivery)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_average_time_diff_delivery_to_delivery_due_date: {
        'name': 'Deviation between delivery and delivery due date',
        'description': 'Average deviation from the delivery to the delivery due date.',
        'formula': ' Ø (date difference delivery until delivery due date)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'requireFact': 'FALSE',
        'roundDigits': '0',
      },
      kpi_average_time_diff_invoice_to_payment: {
        'name': ' Average time from invoice to payment',
        'description': ' Average time from receipt of invoice to payment of invoice.',
        'formula': ' Ø (date difference invoice receiving until invoice payment)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_average_time_diff_invoice_to_invoice_due_date: {
        'name': 'Average payment period',
        'description': ' Average agreed time from receipt to payment of invoices (payment term).',
        'formula': 'Ø (date difference invoice receiving until invoice due date',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_average_time_diff_payment_to_invoice_due_date: {
        'name': 'Deviation between payment and invoice due date',
        'description': 'Average deviation from the payment to the invoice due date.',
        'formula': 'Ø (date difference payment until invoice due date)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_transactions_skonto: {
        'name': ' Number of transactions with discount potential',
        'description': 'Total number of invoice items with cash discount potential. ',
        'formula': '# transactions -> filter discount potential available',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_transactions_with_skonto_taken: {
        'name': 'Number of transactions with cash discount taken',
        'description': ' Total number of invoice items with cash discount taken (cash discount potential). ',
        'formula': '# transactions -> filter discount potential available & taken',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_transactions_without_skonto_taken: {
        'name': ' Number of transactions without cash discount taken',
        'description': 'Total number of invoice items for which available cash discount potential was not taken.',
        'formula': '# transactions -> filter discount potential available & not taken',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_transactions_skonto: {
        'name': 'Share of transactions with cash discount potential',
        'description': 'Percentage of invoice items with cash discount available (cash discount potential).',
        'formula': '(# transaction -> filter cash discount available) / (# transactions)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_transactions_with_skonto_taken: {
        'name': ' Share of transactions with cash discount taken',
        'description': ' Percentage share of invoice items with cash discount taken (according to agreed payment terms).',
        'formula': '(# transactions -> filter cash discount available & taken) / (# Transaktionen)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_transactions_without_skonto_taken: {
        'name': ' Share of transactions without cash discount taken ',
        'description': ' Percentage share of invoice items without cash discount taken (missed payment terms). ',
        'formula': '(# transactions -> cash discount available & not taken) / (# transactions)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_skonto_amount: {
        'name': 'Total cash discount amount',
        'description': 'Sum of the possible cash discount for transactions where cash discount has been agreed.',
        'formula': '∑ cash discount -> filter cash discount available',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_skonto_amount_with_skonto_taken: {
        'name': 'Total cash discount taken',
        'description': 'Sum of cash discount taken due to on-time payment.',
        'formula': '∑ cash discount -> filter cash discount available & taken',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_skonto_amount_without_skonto_taken: {
        'name': 'Total cash discount not taken',
        'description': 'Sum of cash discount not taken due to on-time payment.',
        'formula': '∑ cash discount -> filter cash discount available & not taken',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_skonto_amount_with_skonto_taken: {
        'name': 'Share of cash discount taken from total cash discount amount',
        'description': 'Share of the taken cash discount amount from the total possible cash discount amount.',
        'formula': '(∑ cash discount -> filter cash discount available & taken) / (∑ cash discount -> filter cash discount available)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_skonto_amount_without_skonto_taken: {
        'name': 'Share of cash discount not taken from total cash discount amount',
        'description': 'Proportion of the non-taken cash discount of the total possible cash discount amount (also called cash discount potential).',
        'formula': '∑ cash discount -> filter cash discount available & not taken) / (∑ cash discount -> filter cash discount available)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_average_skonto_rate_from_spend: {
        'name': '⌀ Cash discount rate',
        'description': 'Average discount rate (discountable rate for early payment of invoices).',
        'formula': 'Ø cash discount percent rate -> filter cash discount available',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_transactions_per_employee: {
        'name': 'Transactions per procurement employee',
        'description': 'Average number of invoice/order items per employee in procurement.',
        'formula': ' # transactions / # employees in procurement',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_spend_per_employee: {
        'name': 'Spend per procurement employee',
        'description': 'Average order/invoice volume per procurement employee. ',
        'formula': ' ∑ spend / # employees in procurement',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_spend_of_revenue: {
        'name': 'Share of spend from turnover',
        'description': 'Percentage share of total spend from revenue.',
        'formula': '∑ spend / yearly revenue',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NO_TIME_FILTER',
        'roundDigits': '0',
      },
      kpi_spend_budget_deviation_share: {
        'name': 'Percentage budget deviation',
        'description': 'Relative deviation of the order/invoice volume from the budget in the specified year.',
        'formula': ' ((∑ spend) - (∑ budget)) / (∑ budget)',
        'customFrontendHandling': 'TRUE',
        'customFrontendHandlingType': 'BUDGET',
        'roundDigits': '0',
      },
      kpi_spend_budget_deviation_total: {
        'name': 'Total budget deviation',
        'description': 'Total volume of purchase order/invoice values outside the budget defined in the period.',
        'formula': ' (∑ spend) - (∑ budget)',
        'customFrontendHandling': 'TRUE',
        'customFrontendHandlingType': 'BUDGET',
        'roundDigits': '0',
      },
      kpi_spend_over_budget_count: {
        'name': 'Number of budget overruns',
        'description': 'Total volume of purchase order/invoice values outside  the budget defined in the period.',
        'formula': '∑ single dimensions -> filter budget exceeded',
        'customFrontendHandling': 'TRUE',
        'customFrontendHandlingType': 'DO_NOT_SHOW',
        'roundDigits': '0',
      },
      kpi_spend_with_addressable: {
        'name': 'Addressable Spend',
        'description': '',
        'formula': '∑ Spend -> filter with addressable',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'requireFact': 'FALSE',
        'roundDigits': '0',
      },
      kpi_spend_with_touched: {
        'name': 'Touched Spend',
        'description': '',
        'formula': '∑ Spend -> filter mit touched',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'requireFact': 'FALSE',
        'roundDigits': '0',
      },
      kpi_transactions_with_contract: {
        'name': 'Number of transactions with contract',
        'description': 'Total number of purchase order/invoice items related to a contract.',
        'formula': '# transactions -> filter with contract reference',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_spend_with_contract: {
        'name': 'Spend with contract',
        'description': 'Total volume of purchase order/invoice values related to a contract.',
        'formula': '∑ spend ->filter with contract reference',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_transactions_with_contract: {
        'name': 'Share of transactions with contract',
        'description': 'Relative share of purchase order/invoice items with reference to a contract.',
        'formula': '(# transactions -> filter with contract reference) / # transactions',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_spend_with_contract: {
        'name': 'Share of spend with contract',
        'description': 'Relative share of order/invoice volume with reference to a contract.',
        'formula': '(∑ spend -> filter with contract reference) / (∑ spend)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_transactions_via_eprocurement: {
        'name': 'Transactions via eProcurement',
        'description': 'Total number of purchase order/invoice items processed via eProcurement.',
        'formula': '# transactions -> filter via eProcurement',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_spend_via_eprocurement: {
        'name': 'Spend via eProcurement',
        'description': 'Total order/invoice volume processed via eProcurement. ',
        'formula': '∑ spend -> filter via eProcurement',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_transactions_via_eprocurement: {
        'name': 'Share of transactions via eProcurement',
        'description': 'Percentage of purchase order/invoice items that were processed via eProcurement. ',
        'formula': '(# transactions -> filter via eProcurement) / (# transactions)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_spend_via_eprocurement: {
        'name': 'Share of spend via eProcurement',
        'description': 'Percentage of purchase order/invoice volume that were processed via eProcurement. ',
        'formula': '(∑ spend -> filter via eProcurement) / (∑ spend)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_transactions_via_catalog: {
        'name': 'Transactions via catalog',
        'description': 'Total number of order/invoice items ordered via catalogs.',
        'formula': '# transactions -> filter via catalogue',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_spend_via_catalog: {
        'name': 'Spend via catalog',
        'description': ' Order/invoice volume ordered via catalogs.',
        'formula': '∑ spend -> filter via catalogue',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_transactions_via_catalog: {
        'name': 'Share of catalog transactions',
        'description': 'Relative share of order/invoice items ordered via catalogs.',
        'formula': '(# transactions -> filter via catalogue) / (# transactions)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_spend_via_catalog: {
        'name': 'Share of catalog spend',
        'description': 'Relative share of order/invoice volume ordered via catalogs.',
        'formula': '(∑ spend -> filter via catalogue) / (∑ spend)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_average_spend_per_quantity: {
        'name': 'Average unit price',
        'description': 'Average price per unit of measure.',
        'formula': ' Ø (spend / quantity)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_transactions_with_delivery_on_time: {
        'name': 'Share of transactions delivery on time',
        'description': 'Percentage share of order/invoice items with delivery date met (on-time).',
        'formula': '(# transactions -> filter delivery on time) / (# transactions)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_spend_with_delivery_on_time: {
        'name': 'Share of spend with deliver on-time',
        'description': ' Percentage of order/invoice volume with delivery date met (on-time delivery).',
        'formula': '(∑ spend -> filter delivery on time) / (∑ spend)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_transactions_with_payment_on_time: {
        'name': 'Share of transactions with payment on time',
        'description': 'Percentage share of order/invoice items with invoice due date met (on-time payment).',
        'formula': '(# transactions -> filter payment on time) / (# transactions)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_share_spend_with_payment_on_time: {
        'name': 'Share of spend with payment on-time',
        'description': ' Percentage of order/invoice volume with invoice due date date met (on-time payment).',
        'formula': '(∑ spend -> filter payment on time) / (∑ spend)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'roundDigits': '0',
      },
      kpi_savings_spend_managed: {
        'name': 'Managed volume',
        'description': 'Sum of volume before negotiation.',
        'formula': '∑ Managed volume',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'requireFact': 'FALSE',
        'roundDigits': '0',
      },
      kpi_savings_spend_awarded: {
        'name': 'Awarded volume',
        'description': 'Sum of volume after negotiation.',
        'formula': '∑ Awarded volume',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'requireFact': 'FALSE',
        'roundDigits': '0',
      },
      kpi_savings_spend_negotiated: {
        'name': 'Negotiation result',
        'description': 'Sum of savings without period accrual.',
        'formula': '∑ Savings without period accrual',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'requireFact': 'FALSE',
        'roundDigits': '0',
      },
      kpi_savings_spend_negotiated_per_project: {
        'name': 'Negotiation result per project',
        'description': 'Sum of savings without period accrual per project.',
        'formula': '(∑ Savings without period accrual) / (# projects)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'requireFact': 'FALSE',
        'roundDigits': '0',
      },
      kpi_savings_spend_implemented: {
        'name': 'Implemented savings',
        'description': 'Sum of savings with period accrual.',
        'formula': '∑ Savings with period accrual',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'requireFact': 'FALSE',
        'roundDigits': '0',
      },
      kpi_savings_spend_implemented_per_project: {
        'name': 'Implemented savings per project',
        'description': 'Sum of savings with period accrual per project.',
        'formula': '(∑ Savings with period accrual) / (# projects)',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'NONE',
        'requireFact': 'FALSE',
        'roundDigits': '0',
      },
      // individual KPI
      kpi_individual: {
        'name': 'Individuelle KPI 1',
        'description': 'tbd',
        'formula': 'tbd',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'INDIVIDUAL',
        'requireFact': 'FALSE',
        'roundDigits': '0',
      },
      kpi_procurement_costs: {
        'name': 'Kosten des Einkaufs',
        'description': '',
        'formula': '',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'INDIVIDUAL',
        'requireFact': 'FALSE',
        'roundDigits': '0',
      },
      kpi_s2c_costs: {
        'name': 'Kosten von S2C',
        'description': '',
        'formula': '',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'INDIVIDUAL',
        'requireFact': 'FALSE',
        'roundDigits': '0',
      },
      kpi_roi: {
        'name': 'Procurement Return on Invest (for each €)',
        'description': 'Diese Kennzahl gibt den Wirkungsgrad der Einkaufsabteilung an, sie setzt die Kosten des Einkaufs ins Verhältnis zu den generierten Savings.',
        'formula': 'Savings / Kosten des Einkaufs',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'INDIVIDUAL',
        'requireFact': 'FALSE',
        'roundDigits': '2',
      },
      kpi_roi_s2c: {
        'name': 'S2C Return on Invest (for each €)',
        'description': 'Diese Kennzahl gibt den Wirkungsgrad der S2C-Kostenstelle an, sie setzt die Kosten von S2C ins Verhältnis zu den generierten Savings.',
        'formula': 'Savings / Kosten der S2C-Kostenstelle',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'INDIVIDUAL',
        'requireFact': 'FALSE',
        'roundDigits': '2',
      },
      kpi_count_project_per_employee: {
        'name': 'S2C number of reported projects per buyer',
        'description': 'This KPI indicates the average number of reported projects (completed + has savings items) per buyer',
        'formula': 'Number of projects / number of buyers',
        'customFrontendHandling': 'FALSE',
        'customFrontendHandlingType': 'INDIVIDUAL',
        'requireFact': 'FALSE',
        'roundDigits': '2',
      },
    },
    cluster: {
      favourites: {
        'name': 'Favorites',
        'icon': 'mdi-star-outline',
      },
      orga_favourites: {
        'name': 'Group-Favorites',
        'icon': 'mdi-star-settings',
      },
      general: {
        'name': 'General',
        'icon': 'mdi-view-compact-outline',
      },
      maverick_buying: {
        'name': 'Maverick-Buying',
        'icon': 'mdi-alpha-x-circle-outline',
      },
      count: {
        'name': 'Count',
        'icon': 'mdi-abacus',
      },
      time: {
        'name': 'Time',
        'icon': 'mdi-timer-sand',
      },
      skonto: {
        'name': 'Cash discount',
        'icon': 'mdi-cash-refund',
      },
      budget: {
        'name': 'Budget',
        'icon': 'mdi-cash',
      },
      contract: {
        'name': 'Contract',
        'icon': 'mdi-file-document-edit-outline',
      },
      eprocurement: {
        'name': 'eProcurement',
        'icon': 'mdi-monitor',
      },
      catalog: {
        'name': 'Catalog',
        'icon': 'mdi-book-open-page-variant-outline',
      },
      quantity: {
        'name': 'Quantity',
        'icon': 'mdi-format-line-weight',
      },
      savings: {
        'name': 'Savings',
        'icon': 'mdi-cash-check',
      },
      backlog: {
        name: 'Other KPI',
        icon: 'mdi-dots-horizontal',
      },
      individual: {
        name: 'Individual KPI',
        icon: 'mdi-dots-horizontal',
      },
      all: {
        'name': 'Show all',
        'icon': 'mdi-notification-clear-all',
      },
    },
    legend: {
      very_good: "above target",
      good: "above target value, but with risk of falling below target soon",
      bad: "below target, but with chance to reach target soon",
      very_bad: "below target",
      undefined: "no target value yet available or performance cannot be calculated",
    },
  },
  savings: {
    valueTypes: {
      spend_managed: 'Managed Volume',
      spend_awarded: 'Awarded Volume',
      spend_realized: 'Realized Volume',
      spend_savings: 'Savings',
      spend_savings_reduction: 'Cost Reduction',
      spend_savings_avoidance: 'Cost Avoidance',
      spend_savings_missed: 'Missed Savings',
      spend_savings_opex: 'OpEx',
      spend_savings_capex: 'CapEx'
    },
    module: {
      negotiationResult: 'Negotiation Result',
      negotiationResultDef: 'The savings value is reported without period accrual, in other words at the full value at the start of the contract',
      negotiationResultDef___utdi: 'Periodenunabhängige Summe aller von CP verantworteten Cost Reduction und Cost Avoidance Initiativen',
      implementedSavings: 'Implemented Savings',
      implementedSavingsDef: 'The savings value is reported on an accrual basis, in other words, from the start of the contract it is split over the months of the contract term',
      implementedSavingsDef___utdi: 'Summe aller verhandelten Einsparungen zum Zeitpunkt des Vertragsschlusses; über die gesamte Vertragslaufzeit; kalkuliert wird mit prognostizierten Mengen',
      managedVolume: 'Managed Volume',
      managedVolumeDef: 'Indicates the negotiated volume, meaning the value without savings and procurement influence',
      managedVolumeDef___utdi: 'Einkaufsvolumen, vor dem Beginn der Verhandlungen',
      savingsShare: 'Savings share from Managed Volume',
      savingsShareDef: 'Indicates the share of savings of the managed volume in percent',
      countProjects: 'Number of projects',
      countProjectsDef: 'Number of projects realised in the selected period',
      countProjectsDef___utdi: 'Projekte auf Grundlage von Sourcing Aktivitäten',
      costAvoidanceDef: 'The share that is attributable to cost avoidance. In the case of cost avoidance, the procurement success lies in the cost discount compared to the first offer or price increase from a supplier.',
      costAvoidanceDef___utdi: 'Periodenabhängige Abwendung von Kostensteigerungen und Reduzierung von nicht budegetierten Kosten aus neuen Bedarfen, häufig auch als „Soft Savings“ bezeichnet',
      costReductionDef: 'The share attributable to a cost reduction. A cost reduction occurs when the costs of an existing product or service are reduced below the previous value.',
      costReductionDef___utdi: 'Periodenabhängige  Preis- bzw. Kostensenkung bestehender Kosten relativ zur Vorperiode, häufig auch als „Hard Savings“ bezeichnet',
      savingsPerCategory: 'Savings per category',
      savingsPer: 'Savings per...',
      projectOverview: 'Project overview',
    },
    projects: {
      headline: 'Savings Projects',
      create: 'Add savings project',
      edit: 'Edit savings project',
      created_by: 'Created by',
      status: 'Status',
      name: 'Name',
      timestamp_contract_signature_date: 'Contract signature date',
    }
  },
  actions: {
    lifecycles: {
      'ALL': 'All',
      'IDENTIFIED': 'Identified',
      'IN_PROGRESS': 'In Progress',
      'IN_REVIEW': 'In Review',
      'ON_HOLD': 'On Hold',
      'COMPLETED': 'Completed',
      'CANCELLED': 'Cancelled',
    },
    targets: {
      'COST_REDUCTION': 'Cost reduction',
      'RISK_AVOIDANCE': 'Risk mitigation',
      'STANDARDIZATION': 'Standardization',
      'PROCESS_OPTIMIZATION': 'Process optimization',
      'OPTIMIZE_CONTRACTS': 'Optimize contract conditions',
      'VENDOR_CONSOLIDATION': 'Vendor consolidation',
      'SUSTAINABILITY': 'Sustainability',
      'LKSG': 'LkSG / CSDDD compliance',
      'CSRD': 'Sustainability Reporting (CSRD)',
      'MAVERICK_BUYING': 'Reduce Maverick Buying',
      'null': 'Not set',
    },
    priorities: {
      'LOW': 'Low',
      'MEDIUM': 'Medium',
      'HIGH': 'High',
    },
    types: {
      'GENERAL': 'General',
      'NEGOTIATION': 'Negotiation',
      'TENDER': 'Tender',
      'STRATEGY': 'Strategy development',
      'INTERNAL_OPTIMIZATION': 'Internal optimazation project',
      'OTHER': 'Others',
      'OTHER___utdi': 'Maverick Buying',
    },
    nonFinancialBenefits: {
      'risk mitigation': 'Risk mitigation',
      'process optimization': 'Process optimization',
      'optimized payment terms': 'Optimized payment terms',
      'improve contract conditions': 'Improved contract conditions',
      'sustainability': 'Sustainability',
    },
    savingsMissedReasons: {
      'no procurement involvement': 'No procurement involvement',
      'late procurement involvement': 'Procurement involvement too late',
      'others': 'Others',
    },
    module: {
      lifecycle: 'Lifecycle',
      start: 'Start',
      end: 'End',
      team: 'Team',
      noTeamMembers: 'No team members',
      targets: 'Targets',
      openApproval: 'You have an open approval ToDo here',
      noResponsiblePerson: 'No responsible person',
      notEditable: 'This project is not editable due to its lifecycle.',
      projectsPerPage: 'Projects per page: ',
      selectLifecyclesConfirm: 'Please select and confirm lifecycle',
    },
    dialog: {
      placeholderName: 'How shall the action be called?',
      priority: 'Priority',
      lifecyclePhase: 'Lifecycle-Phase',
      type: 'Type',
      projectStart: 'Project start',
      projectEnd: 'Project end',
      choose: 'Choose',
      chooseStartDate: 'Choose start date',
      chooseEndDate: 'Choose end date',
      responsible: 'Responsible',
      teamMembers: 'Team members',
      shirtSize: 'Project size',
      createdBy: 'Created by',
      createdAt: 'Created at',
      tasks: 'Tasks',
      hideHints: 'Hide hints',
      showHints: 'Show hints',
      description: 'Description',
      dataReferences: 'Data referenced',
      savingsInformation: 'Savings',
      nonFinancialBenefits: 'Non financial benefits',
      comments: 'Comments',
      attachments: 'Attachments',
      approvals: 'Approvals & Completion',
      dateContractSignature: 'Contract signature date',
      dateContractStart: 'Contract start date',
      dateContractEnd: 'Contract end date',
      positionDescription: 'Description of the position',
      allocations: 'Allocations',
      category_id: 'Category',
      vendor_id: 'Vendor',
      company_code_id: 'Company Code',
      commercialAspects: 'Commercial Aspects',
      managedSpend: 'Managed Volume',
      awardedVolume: 'Awarded Volume',
      savings: 'Savings',
      savingsAvoidance: 'Cost Avoidance',
      savingsReduction: 'Cost Reduction',
      capexAvoidanceLabel: 'CapEx of Cost Avoidance',
      opexAvoidanceLabel: 'Opex of Cost Avoidance',
      capexReductionLabel: 'CapEx of Cost Reduction',
      opexReductionLabel: 'Opex of Cost Reduction',
      yes: 'Yes',
      no: 'No',
      reasonsMissedSavings: 'Reasons for Missed Savings',
      delete: 'Delete',
      add: 'Add',
      noPositionsYet: 'No positions yet.',
      addPosition: 'Add position',
      title: 'Title',
      jumpToActionLog: 'Jump to Action-Log',
      close: 'Close',
      nameHasToBeSet: 'Project name must be set',
      maximumCharacters: 'Maximum 2,000 Characters',
      leaveComment: 'Leave a comment...',
      add: 'Add',
      statusApprovals: 'Status of the approval flow for this project:',
      responsibleForApproval: 'Responsible user',
      decline: 'Decline',
      declineExplanation: 'This resets the project to "In progress" and resets the approval flow',
      approve: 'Approve',
      restartApprovalFlow: 'Restart approval flow',
      startApprovalFlow: 'Start approval flow',
      loadApprovals: 'Load suitable approval flows',
      addPositionsFirst: 'Please add savings positions first',
      loadApprovalsExplanation: 'This loads the approval flows available for this project',
      setToDone: 'Set to completed',
      setToDoneWOPositions: 'Set project to completed without savings positions',
      setToInProcessFirst: 'Please set project to "In progress" first',
      resolveIssuesFirst: 'There are validation errors in the savings positions, please resolve them first',
      noApprovalsFound: 'No suitable approval flows were found.',
      notSubmittedYet: 'Position not yet submitted',
      mustBeSet: 'must be set',
      setToManagedMinusAwarded: 'set to Managed Spend - Awarded Volume',
      savingsTypes: 'Savings types',
      setToSavings: 'set to 100% of savings',
      capexVsOpexCA: 'Capex vs. Opex (Cost Avoidance)',
      capexVsOpexCR: 'Capex vs. Opex (Cost Reduction)',
      setToCA: 'set to 100% of Cost Avoidance',
      setToCR: 'set to 100% of Cost Reduction',
      validationErrorsIncluded: 'Validation errors included - click to see them',
      saveSavingsPosition: 'Save savings position',
      editSavingsPosition: 'Update savings position',
      reasonsForMissedSavings: 'Reason for Missed Savings',
      typeOfBenefit: 'Type of the benefit',
      saveBenefitsPosition: 'Save position',
      editBenefitsPosition: 'Update position',
    },
    menus: {
      unsavedChangesSure: 'There are unsaved changes in savings positions, are you sure you want to discard them?',
      declineApproval: 'Declie approval',
      areYouSure: 'Are you sure?',
      declineReason: 'Please enter the reason why the approval is being rejected. This reason will be saved as a comment in the project.',
      declineReasonShort: 'Please enter reason',
      add: 'Add',
    }
  }
}
