

// VUE
import { Component, Vue, Watch } from 'vue-property-decorator';

// API
import { api } from '@/api';
import { apiCluster } from '@/api-cluster';

// STORE MAIN
import {
  dispatchCheckLoggedIn,
  dispatchUpdateUserProfile,
  dispatchUserLogOut
} from '@/store/main/actions';
import {
  commitSetPredictionsLoading,
  commitSetLUTLoading,
  commitSetClusterLoading,
} from '@/store/main/mutations';
import {
  readUserProfile,
  readPredictionsLoading,
  readLUTLoading,
  readClusterLoading,
  readToken,
  readIsLoggedIn,
} from '@/store/main/getters';

// STORE APP
import {
  dispatchShowStandardErrorToast,
  dispatchShowSuccessToast,
} from '@/store/app/actions';
import {
  readToast,
} from '@/store/app/getters';

// INTERFACES
import {
  IUserProfileUpdate,
  ICheckTaskResult,
} from '@/interfaces';

@Component
export default class App extends Vue {

  private checkPredictionTaskInterval: number | null = null;
  private checkLUTTaskInterval: number | null = null;
  private checkClusterTaskInterval: number | null = null;
  private checkLoginTaskInterval: number | null = null;

  async mounted(): Promise<void> {
    this.$nextTick(() => {
      window.addEventListener('beforeunload', this.beforeUnload);
    });
  }

  async beforeDestroy() {
    await this.beforeUnload();
  }

  async beforeUnload() {
    if (this.checkPredictionTaskInterval) {
      clearInterval(this.checkPredictionTaskInterval);
    }
    if (this.checkLUTTaskInterval) {
      clearInterval(this.checkLUTTaskInterval);
    }
    if (this.checkClusterTaskInterval) {
      clearInterval(this.checkClusterTaskInterval);
    }
    if (this.checkLoginTaskInterval) {
      clearInterval(this.checkLoginTaskInterval);
    }
    window.removeEventListener('beforeunload', this.beforeUnload);
  }

  async created() {
    await dispatchCheckLoggedIn(this.$store);

    const user = readUserProfile(this.$store);
    if (user && user.options.lang) {
      this.$i18n.locale = user.options.lang
      this.$vuetify.lang.current = user.options.lang
    }

    this.checkLogin();
  }

  get toast() {
    return readToast(this.$store);
  }

  // REFRESH MSG
  get userRefresh(): boolean {
    const user = readUserProfile(this.$store);
    if (this.isLoggedIn && user && user.refresh) {
      return user.refresh;
    }
    else {
      return false;
    }
  }

  async reloadPage() {
    // Update user refresh boolean
    const updatedProfile: IUserProfileUpdate = {};
    updatedProfile.refresh = false;
    await dispatchUpdateUserProfile(this.$store, updatedProfile);

    // Reload page
    window.location.reload();
  }

  // PREDICTION TASK AND MSG
  get predictionsLoading(): boolean {
    return readPredictionsLoading(this.$store);
  }

  @Watch('predictionsLoading')
  async onPredictionsLoadingChange(value: number) {
    if (value) {
      // start prediction task check interval
      this.checkPredictionLoading();
      await api.analyticsSpendMeta(readToken(this.$store));
    } else {
      dispatchShowSuccessToast(this.$store, this.$t("categoryLab.predictions.successMsg"));
    }
  }

  private async checkPredictionLoading() {
    if (this.checkPredictionTaskInterval === null && this.predictionsLoading) {
      try {
        this.checkPredictionTaskInterval = setInterval(async () => {
          try {
            const response = await api.categoryLabCheck(readToken(this.$store));
            if (response.data) {
              let checkResponse: ICheckTaskResult = response.data;
              if (checkResponse.state == 'SUCCESS') {
                commitSetPredictionsLoading(this.$store, false);
                if (this.checkPredictionTaskInterval) {
                  clearInterval(this.checkPredictionTaskInterval);
                  this.checkPredictionTaskInterval = null;
                }
              }
              else if (checkResponse.state == 'FAILURE') {
                commitSetPredictionsLoading(this.$store, false);
                dispatchShowStandardErrorToast(this.$store, { error: this.$t('error.other') });
                if (this.checkPredictionTaskInterval) {
                  clearInterval(this.checkPredictionTaskInterval);
                  this.checkPredictionTaskInterval = null;
                }
              }
            }
          } catch (err) {
            dispatchShowStandardErrorToast(this.$store, { error: err });

            if (this.checkPredictionTaskInterval) {
              clearInterval(this.checkPredictionTaskInterval);
              this.checkPredictionTaskInterval = null;
            }
          }
        }, 10000);
      } catch (err) {
        dispatchShowStandardErrorToast(this.$store, { error: err });
      }
    }
  }

  // LUT TASK AND MSG
  get lutLoading(): boolean {
    return readLUTLoading(this.$store);
  }

  @Watch('lutLoading')
  async onLUTLoadingChange(value: number) {
    if (value) {
      // start prediction task check interval
      this.checkLUTLoading();
      await api.analyticsSpendMeta(readToken(this.$store));
    } else {
      dispatchShowSuccessToast(this.$store, this.$t("lut.successMsg"));
    }
  }

  private async checkLUTLoading() {
    if (this.checkLUTTaskInterval === null && this.lutLoading) {
      try {
        this.checkLUTTaskInterval = setInterval(async () => {
          try {
            const response = await api.lutCheck(readToken(this.$store));
            if (response.data) {
              let checkResponse: ICheckTaskResult = response.data;
              if (checkResponse.state == 'SUCCESS') {
                commitSetLUTLoading(this.$store, false);
                if (this.checkLUTTaskInterval) {
                  clearInterval(this.checkLUTTaskInterval);
                  this.checkLUTTaskInterval = null;
                }
              }
              else if (checkResponse.state == 'FAILURE') {
                commitSetLUTLoading(this.$store, false);
                dispatchShowStandardErrorToast(this.$store, { error: this.$t('error.other') });
                if (this.checkLUTTaskInterval) {
                  clearInterval(this.checkLUTTaskInterval);
                  this.checkLUTTaskInterval = null;
                }
              }
            }
          } catch (err) {
            dispatchShowStandardErrorToast(this.$store, { error: err });

            if (this.checkLUTTaskInterval) {
              clearInterval(this.checkLUTTaskInterval);
              this.checkLUTTaskInterval = null;
            }
          }
        }, 10000);
      } catch (err) {
        dispatchShowStandardErrorToast(this.$store, { error: err });
      }
    }
  }

  // CLUSTER TASK AND MSG
  get clusterLoading(): boolean {
    return readClusterLoading(this.$store);
  }

  @Watch('clusterLoading')
  async onClusterLoadingChange(value: number) {
    if (value) {
      // start prediction task check interval
      this.checkClusterLoading();
      await api.analyticsSpendMeta(readToken(this.$store));
    } else {
      dispatchShowSuccessToast(this.$store, this.$t("cluster.successMsg"));
    }
  }

  private async checkClusterLoading() {
    if (this.checkClusterTaskInterval === null && this.clusterLoading) {
      try {
        this.checkClusterTaskInterval = setInterval(async () => {
          try {
            const response = await apiCluster.clusterCheck(readToken(this.$store));
            if (response.data) {
              let checkResponse: ICheckTaskResult = response.data;
              if (checkResponse.state == 'SUCCESS') {
                commitSetClusterLoading(this.$store, false);
                if (this.checkClusterTaskInterval) {
                  clearInterval(this.checkClusterTaskInterval);
                  this.checkClusterTaskInterval = null;
                }
              }
              else if (checkResponse.state == 'FAILURE') {
                commitSetClusterLoading(this.$store, false);
                dispatchShowStandardErrorToast(this.$store, { error: this.$t('error.other') });
                if (this.checkClusterTaskInterval) {
                  clearInterval(this.checkClusterTaskInterval);
                  this.checkClusterTaskInterval = null;
                }
              }
            }
          } catch (err) {
            dispatchShowStandardErrorToast(this.$store, { error: err });

            if (this.checkClusterTaskInterval) {
              clearInterval(this.checkClusterTaskInterval);
              this.checkClusterTaskInterval = null;
            }
          }
        }, 10000);
      } catch (err) {
        dispatchShowStandardErrorToast(this.$store, { error: err });
      }
    }
  }


  // method for checking login
  get isLoggedIn(): boolean {
    const loggedIn = readIsLoggedIn(this.$store);
    if (loggedIn) {
      return true;
    }
    else {
      return false;
    }
  }

  @Watch('isLoggedIn')
  async onLoggedInChange(value: boolean) {
    if (value) {
      // Start to check login task
      this.checkLogin();

      // Start to check prediction task
      const response = await api.categoryLabCheck(readToken(this.$store));
      if (response.data) {
        let checkResponse: ICheckTaskResult = response.data;
        // Check if state is SUCCESS or FAILURE
        if (checkResponse.state == 'PENDING' || checkResponse.state == 'STARTED') {
          commitSetPredictionsLoading(this.$store, true);
        }
      }
      // Start to check lut task
      const response_lut = await api.lutCheck(readToken(this.$store));
      if (response_lut.data) {
        let checkResponse: ICheckTaskResult = response_lut.data;
        // Check if state is SUCCESS or FAILURE
        if (checkResponse.state == 'PENDING' || checkResponse.state == 'STARTED') {
          commitSetLUTLoading(this.$store, true);
        }
      }
    }
    else {
      if (this.checkLoginTaskInterval) {
        clearInterval(this.checkLoginTaskInterval);
        this.checkLoginTaskInterval = null;
      }

      if (this.predictionsLoading) {
        commitSetPredictionsLoading(this.$store, false);
        if (this.checkPredictionTaskInterval) {
          clearInterval(this.checkPredictionTaskInterval);
          this.checkPredictionTaskInterval = null;
        }
      }

      if (this.lutLoading) {
        commitSetLUTLoading(this.$store, false);
        if (this.checkLUTTaskInterval) {
          clearInterval(this.checkLUTTaskInterval);
          this.checkLUTTaskInterval = null;
        }
      }
    }
  }

  private async checkLogin() {
    if (this.isLoggedIn) {
      this.checkLoginTaskInterval = setInterval(async () => {
        try {
          await api.getMe(readToken(this.$store));
        } catch (err) {
          if (this.checkLoginTaskInterval) {
            clearInterval(this.checkLoginTaskInterval);
          }
          await dispatchUserLogOut(this.$store);
        }
      }, 300000);
    } else {
      ;
      if (this.checkLoginTaskInterval) {
        clearInterval(this.checkLoginTaskInterval);
      }
    }
  }
}
