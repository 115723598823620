import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { IResearchFilterState } from './state';

const defaultFilterState: IResearchFilterState = {
  time: {
    minTime: '',
    maxTime: '',
    currentPeriod: 'YTD',
    currentPeriodValues: [],
    comparePeriod: {
      Gesamt: 'Keinen',
      YTD: 'Keinen',
      Jahr: 'Keinen',
      Quartal: 'Keinen',
      Eigen: 'Keinen',
    },
    comparePeriodValues: [],
  },
  dimensions: [],
  dimensionsTransactions: [],
  dimensionsLoaded: false,
  categoryDrillPath: [],
  showCategoryTypes: 'TRANSACTION_BASED',
  extractTypeSwitchInitialized: false,
  extractTypeSwitchEnabled: false,
  extractTypeSwitchValue: undefined,
  quantitiesEnabled: false,
  harmonized: false,
  valueType: 'SPEND',
  valueTypeSavings: 'spend_savings',
  timeChartAggregation: 'MONTH',
  timeChartAggregationSavings: 'MONTH',
  reloadTrigger: false,
  reloadTriggerTime: false,
  categoryDrillChangeElement: { drillChangeElement: [], setFilter: true },
  lastFilterInteractionSavedView: false,
  savedViewValue: {},
  savingsPeriodAccrual: false,
  savingsShowAll: true,
  moduleSpecificDimensions: { 'savings': ['test'] },
};

export const researchModule = {
  name: 'research',
  namespaced: false,
  state: defaultFilterState,
  getters,
  mutations,
  actions,
};
