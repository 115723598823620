// OTHER
import axios, { AxiosRequestConfig } from 'axios';
import { apiUrl } from '@/env';

// INTERFACES
import {
  IClusterRule,
  IClusterRuleCreate,
  IClusterRuleUpdate,
  ICluster,
  IClusterMeta,
} from './interfaces/cluster';


function authHeaders(token: string): AxiosRequestConfig {
  if (process.env.NODE_ENV === 'development') {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        client: 'test',
      },
    };
  } else {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
}


export const apiCluster = {
  async clusterReadMeta(
    token: string,
    collection: string,
  ) {
    return axios.get<IClusterMeta>(`${apiUrl}/api/v1/clusters/meta/${collection}`, authHeaders(token));
  },
  async clusterReadAll(
    token: string,
    collection: string,
    skip = 0,
    limit = 500,
  ) {
    return axios.get<ICluster[]>(`${apiUrl}/api/v1/clusters/${collection}?skip=${skip}&limit=${limit}`, authHeaders(token));
  },
  async clusterRuleReadMeta(
    token: string,
    collection: string,
    filterFields: string[] = [],
    filterValues: string[][] = [],
    search = '',
  ) {
    let url = `${apiUrl}/api/v1/cluster_rules/meta/${collection}`;
    if (search && search !== '') url = `${url}?search=${search}`;
    const payload = {
      filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
      filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
    };
    return axios.post(url, payload, authHeaders(token));
  },
  async clusterRuleReadAll(
    token: string,
    collection: string,
    skip = 0,
    limit = 500,
    filterFields: string[] = [],
    filterValues: string[][] = [],
    search = '',
    sortBy: string[] = ["updated_at"],
    sortOrder: number[] = [-1],
  ) {
    let url = `${apiUrl}/api/v1/cluster_rules/filter/${collection}?skip=${skip}&limit=${limit}`;
    if (search && search !== '') url = `${url}&search=${search}`;
    const payload = {
      filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
      filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
      sort_by: sortBy,
      sort_order: sortOrder,
    };
    return axios.post<IClusterRule[]>(url, payload, authHeaders(token));
  },
  async clusterRuleCreate(
    token: string,
    payload: IClusterRuleCreate,
  ) {
    return axios.post<IClusterRule>(`${apiUrl}/api/v1/cluster_rules/`, payload, authHeaders(token));
  },
  async clusterRuleUpdate(
    token: string,
    ruleID: string,
    payload: IClusterRuleUpdate,
  ) {
    return axios.put<IClusterRule>(`${apiUrl}/api/v1/cluster_rules/${ruleID}`, payload, authHeaders(token));
  },
  async clusterRuleDelete(
    token: string,
    ruleID: string,
  ) {
    return axios.delete(`${apiUrl}/api/v1/cluster_rules/${ruleID}`, authHeaders(token));
  },
  async clusterApplyRules(
    token: string,
  ) {
    return axios.get(`${apiUrl}/api/v1/clusters/cluster/`, authHeaders(token));
  },
  async clusterCheck(token: string) {
    return axios.get(`${apiUrl}/api/v1/clusters/check/`, authHeaders(token));
  },
}
