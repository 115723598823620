import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { 
    IResearchFilterState,
    IResearchFilterDimension,
    IResearchFilterDimensionTransaction,
    IResearchFilterDimensionCustomTypes,
    IResearchFilterDimensionsCustomPages,
} from './state';
import { 
    readResearchFilterDimensions,
    readCategoryDrillPath,
    readExtractTypeSwitchEnabled,
    readExtractTypeSwitchValue,
    readQuantitiesEnabled,
    readResearchCurrentlyFilteredCategories,
} from './getters';
import { 
    commitSetResearchFilterDimensions, 
    commitSetResearchFilterDimensionSingle,
    commitSetResearchFilterDimensionLoaded,
    commitSetResearchFilterDimensionsTransactions,
    commitSetResearchFilterDimensionCompleteSingle,
    commitSetResearchExtractTypeSwitchEnabled,
    commitSetResearchExtractTypeSwitchValue,
    commitSetResearchQuantitiesEnabled,
    commitSetDrillChangeElement,
    commitSetModuleSpecificDimensions,
} from './mutations';

// Interfaces
import { 
    IDataManagementFieldsConfig, 
} from '@/interfaces/dataManagement';
import { IResearchFilterDimensionCategory } from '@/interfaces/research';

// Others
import fields from '@/configs/fields';

// API
import { api } from '@/api';
import { readOrganizationDBName } from '../main/getters';
import { filter } from 'vue/types/umd';


type ResearchContext = ActionContext<IResearchFilterState, State>;

function sortDimensions( a: any, b: any ) {
  if (a.displayPrio < b.displayPrio) {
    return -1;
  }
  if (a.displayPrio > b.displayPrio) {
    return 1;
  }
  return 0;
}

export const actions = {
  async loadResearchDimensions(context: ResearchContext, payload: {customPage: IResearchFilterDimensionsCustomPages, customerDBName?: string}) {
    try {
      const fieldSettings: {[key: string]: IDataManagementFieldsConfig} = fields.fieldsConfig;
      const response = await api.transactionsPopulatedFields(context.rootState.main.token);
      const responseSavings = (payload && (payload.customPage == 'SAVINGS_DASHBOARD' || payload.customPage == 'ACTION_LOG')) ? await api.savingsFields(context.rootState.main.token) : null;
      const responseProjects = (payload && (payload.customPage == 'SAVINGS_DASHBOARD' || payload.customPage == 'ACTION_LOG')) ? await api.projectsFields(context.rootState.main.token) : null;
      let savingsFields: string[] = [];
      let projectActionsFields: string[] = [];

      if (response.data) {          
          let populatedFields: string[] = response.data;
          
          // Add populated fields from savings (positions)
          if (responseSavings) {
            savingsFields = responseSavings.data;
            for (let savingsField of responseSavings.data) {
              if (!populatedFields.includes(savingsField)) populatedFields.push(savingsField);
            }
          }

          // Add populated fields from projects
          if (responseProjects) {
            for (let projectField of responseProjects.data) {
              if (!populatedFields.includes(projectField)) populatedFields.push(projectField);
              if (!projectActionsFields.includes(projectField)) projectActionsFields.push(projectField);
              if (!savingsFields.includes(projectField)) savingsFields.push(projectField);
            }
          }

          // Add savings positions filters, if needed
          if (payload && payload.customPage == 'SAVINGS_DASHBOARD') {
            const projectFields = Object.entries(fieldSettings).filter(([key, value]) => (value.moduleSpecific === 'SAVINGS_POSITION' && value.showInFilter));
            const projectFieldsObj = Object.fromEntries(projectFields);

            for (let projectField of Object.keys(projectFieldsObj)) {
              if (!populatedFields.includes(projectField)) populatedFields.push(projectField);
              if (!projectActionsFields.includes(projectField)) projectActionsFields.push(projectField);
              if (!savingsFields.includes(projectField)) savingsFields.push(projectField);
            }
          }

          // Add Action-Log filters, if needed
          if (payload && (payload.customPage == 'SAVINGS_DASHBOARD' || payload.customPage == 'ACTION_LOG')) {
            const projectFields = Object.entries(fieldSettings).filter(([key, value]) => value.moduleSpecific === 'PROJECT');
            const projectFieldsObj = Object.fromEntries(projectFields);

            for (let projectField of Object.keys(projectFieldsObj)) {
              if (!populatedFields.includes(projectField)) populatedFields.push(projectField);
              if (!projectActionsFields.includes(projectField)) projectActionsFields.push(projectField);
              if (!savingsFields.includes(projectField)) savingsFields.push(projectField);
            }
          }

          const currentFilteredDimensions = Array.from(readResearchFilterDimensions(context));
          
          let quantitiesEnabled = false;

          let filterDimensions: IResearchFilterDimension[] = [];
          for (const fieldName of populatedFields) {
            if (fieldName in fieldSettings && fieldSettings[fieldName].showInFilter) {
              const pushDimension: IResearchFilterDimension = {
                  dimensionExternalName: fieldName,
                  dimensionInternalName: fieldName,
                  valueType: fieldSettings[fieldName].valueType,
                  hierarchical: (fieldSettings[fieldName].hierarchical) ? true : false,
                  icon: (fieldSettings[fieldName].icon) ? fieldSettings[fieldName].icon as string : '',
                  displayPrio: (fieldSettings[fieldName].displayPrio) ? fieldSettings[fieldName].displayPrio as number : NaN,
                  showInTab: (fieldSettings[fieldName].showInTab) ? fieldSettings[fieldName].showInTab as boolean : false,
                  requiredRoleSavings: (fieldSettings[fieldName].requiredRoleSavings) ? fieldSettings[fieldName].requiredRoleSavings : undefined,
                  showInTabSavings: (fieldSettings[fieldName].showInTabSavings) ? fieldSettings[fieldName].showInTabSavings as boolean : false,
                  isSavingsProjectField: (fieldSettings[fieldName].isSavingsProjectField) ? fieldSettings[fieldName].isSavingsProjectField as boolean : false,
                  isFreetextDropdownField: (fieldSettings[fieldName].isFreetextDropdownField) ? fieldSettings[fieldName].isFreetextDropdownField as boolean : false,
                  showAsKPIField: (fieldSettings[fieldName].showAsKPIField) ? fieldSettings[fieldName].showAsKPIField as boolean : false,
                  isReferencedObject: (fieldSettings[fieldName].isReferencedObject) ? fieldSettings[fieldName].isReferencedObject as boolean : false,
                  customHandling: (fieldSettings[fieldName].customHandling) ? fieldSettings[fieldName].customHandling as boolean : false,
                  customHandlingIdentifier: (fieldSettings[fieldName].customHandlingIdentifier) ? fieldSettings[fieldName].customHandlingIdentifier as IResearchFilterDimensionCustomTypes : undefined,
                  shareRouteLookup: fieldSettings[fieldName].shareRouteLookup,
                  pivotResultLookup: fieldSettings[fieldName].pivotResultLookup,
                  moduleSpecific: (fieldSettings[fieldName].moduleSpecific) ? fieldSettings[fieldName].moduleSpecific : undefined,
                  isUserLookupField: (fieldSettings[fieldName].isUserLookupField !== undefined) ? fieldSettings[fieldName].isUserLookupField : false,
                  preloadFinished: false,
                  availableOptions: [],
                  filteredValues: [],
                  filterSelectAll: false,
                  filterIndeterminate: false,
              };
              // search for prefiltered items
              const prefilter = currentFilteredDimensions.filter(function(dim) {
                return dim.dimensionInternalName == fieldName
              });
              if (prefilter.length > 0) {
                pushDimension.availableOptions = prefilter[0].availableOptions;
                pushDimension.filteredValues = prefilter[0].filteredValues;
                pushDimension.filterSelectAll = prefilter[0].filterSelectAll;
                pushDimension.filterIndeterminate = prefilter[0].filterIndeterminate;
              }
              filterDimensions.push(pushDimension);
            }
            // check for quantities
            if (fieldName == 'quantity') {
              quantitiesEnabled = true;
            }
          }
      
          // sort dimensions per display prio for filter pane and tabs
          filterDimensions = filterDimensions.sort(sortDimensions);
          
          commitSetResearchFilterDimensions(context, filterDimensions);
          if (savingsFields.length > 0) commitSetModuleSpecificDimensions(context, {key: 'SAVINGS_DASHBOARD', dimensions: savingsFields});
          if (projectActionsFields.length > 0) commitSetModuleSpecificDimensions(context, {key: 'ACTION_LOG', dimensions: projectActionsFields});

          commitSetResearchFilterDimensionLoaded(context, true);
      
          // set extract type
          if (!readExtractTypeSwitchEnabled(context)) {
            const switchValue = readExtractTypeSwitchValue(context);
            if (!switchValue) {
              // implement customer-specific ways of setting standard extract type, for now only hard-coded
              if (payload.customerDBName && payload.customerDBName == 'tece') {
                commitSetResearchExtractTypeSwitchValue(context, 'ORDER');
              } else {
                commitSetResearchExtractTypeSwitchValue(context, 'INVOICE');
              }              
            }
          }
      
          // set quantity settings
          if (quantitiesEnabled && !readQuantitiesEnabled(context)) {
            commitSetResearchQuantitiesEnabled(context, quantitiesEnabled);
          }
      
          // prepare transaction attributes
          const filterDimensionsTransactions: IResearchFilterDimensionTransaction[] = [];

          for (const fieldName of populatedFields) {
            if (fieldName in fieldSettings) {
              filterDimensionsTransactions.push({
                dimensionInternalName: fieldName,
                dimensionExternalName: fieldName,
                valueType: fieldSettings[fieldName].valueType,
                transactionViewOrder: fieldSettings[fieldName].transactionViewOrder,
                showInTransactionView: fieldSettings[fieldName].showInTransactionView,
              });
            }
          }
          commitSetResearchFilterDimensionsTransactions(context, filterDimensionsTransactions);
      }
    } catch (err) {
        // console.log(err);
    }


  },
  setResearchDimensionArray(context: ResearchContext, payload: {dimension: string, payload: object[]}) {
    const dimensionFiltered = readResearchFilterDimensions(context).filter(function(dimension) {
      return dimension.dimensionInternalName === payload.dimension;
    })[0];
    const payloadSet = JSON.parse(JSON.stringify(payload.payload));
    commitSetResearchFilterDimensionSingle(context, {index: readResearchFilterDimensions(context).indexOf(dimensionFiltered), payload: payloadSet});
  },
  setResearchDimensionCompleteSingle(context: ResearchContext, payload: {dimension: string, payload: IResearchFilterDimension}) {
    const dimensionFiltered = readResearchFilterDimensions(context).filter(function(dimension) {
      return dimension.dimensionInternalName === payload.dimension;
    })[0];
    commitSetResearchFilterDimensionCompleteSingle(context, {index: readResearchFilterDimensions(context).indexOf(dimensionFiltered), payload: payload.payload});
  },
  setResearchDimensionAvailableAttributes(context: ResearchContext, payload: {dimension: string, payload: object[]}) {
    const dimensionFiltered = readResearchFilterDimensions(context).filter(function(dimension) {
      return dimension.dimensionInternalName === payload.dimension;
    })[0];
    dimensionFiltered.availableOptions = payload.payload;
    dimensionFiltered.preloadFinished = true;
  },
  setResearchDimensionFilterSelectAll(context: ResearchContext, payload: {dimension: string, payload: boolean}) {
    const dimensionFiltered = readResearchFilterDimensions(context).filter(function(dimension) {
      return dimension.dimensionInternalName === payload.dimension;
    })[0];
    dimensionFiltered.filterSelectAll = payload.payload;
  },
  setResearchDimensionFilterIndeterminate(context: ResearchContext, payload: {dimension: string, payload: boolean}) {
    const dimensionFiltered = readResearchFilterDimensions(context).filter(function(dimension) {
      return dimension.dimensionInternalName === payload.dimension;
    })[0];
    dimensionFiltered.filterIndeterminate = payload.payload;
  },
  setResearchDimensionRemoveAllFilters(context: ResearchContext) {
    const dimensions = readResearchFilterDimensions(context);
    dimensions.forEach(function(dimension) {
      dimension.filteredValues = [];
    });
    commitSetResearchFilterDimensions(context, dimensions);
  },
  setResearchCategoryDrillUp(context: ResearchContext, payload: {drillOneUp?: boolean, removeDrill?: boolean, drillElement?: IResearchFilterDimensionCategory}) {
    let elementIndex = 0;
    const currentDrillPath: IResearchFilterDimensionCategory[] = readCategoryDrillPath(context);
    if (payload.drillOneUp) {
        elementIndex = currentDrillPath.length - 1;
    } else if (payload.removeDrill) {
        elementIndex = 0;
    } else if (payload.drillElement) {
      for (const drillPathElement of currentDrillPath) {
          elementIndex = elementIndex + 1;
          if (drillPathElement._id == payload.drillElement._id) {
            break;
          }                
      }
    }
    if (elementIndex == 0) {
      dispatchSetResearchDimensionArray(context, {dimension: 'category_id', payload: []})
      commitSetDrillChangeElement(context, {drillChangeElement: [], setFilter: true});
    } else {
      const newDrillPath: IResearchFilterDimensionCategory[] =  currentDrillPath.slice(0, elementIndex);
      const identifier: string[] = [];
      const names: string[] = [];
      const IDs: string[] = [];
      if (newDrillPath.length > 1) {
        let iDrill = 0;
        for (const drill of newDrillPath) {
          if (iDrill == newDrillPath.length-1) break;
          identifier.push(drill.identifier);
          names.push(drill.name);
          if (drill._id) IDs.push(drill._id as string);
          iDrill++;
        }
      }
      const lastDrill = newDrillPath[newDrillPath.length-1];
      const drillElementSet: IResearchFilterDimensionCategory = {
        _id: lastDrill._id,
        identifier: lastDrill.identifier,
        name: lastDrill.name,
        parents_identifiers: identifier,
        parents_names: names,
        parents_ids: IDs,
        children: null,
      };
      dispatchSetResearchDimensionArray(context, {dimension: 'category_id', payload: [drillElementSet]});
      commitSetDrillChangeElement(context, {drillChangeElement: [{...drillElementSet}], setFilter: true});
    }
  },
  setResearchCategoryDrillDown(context: ResearchContext, drillElement: IResearchFilterDimensionCategory) {
    const drillElementMod: IResearchFilterDimensionCategory = JSON.parse(JSON.stringify(drillElement));
    if (drillElementMod['hierarchy'] && drillElementMod['hierarchy'] > 1 && drillElementMod.identifier == '--') {
      drillElementMod._id = '--';
    }

    // find out, if different filter path is taken due to multi-filters
    const currentlyFilteredCategories: object[] | undefined = readResearchCurrentlyFilteredCategories(context);
    const parentIDs: string[] = [];
    if (currentlyFilteredCategories && currentlyFilteredCategories.length > 0) {
      for (const singleCategory of currentlyFilteredCategories as IResearchFilterDimensionCategory[]) {
        if (singleCategory.parents_ids && singleCategory.parents_ids.length > 0) {
          for (const parentID of singleCategory.parents_ids) {
            parentIDs.push(parentID);
          }
        }
      }
    }
    
    // decide, if multi-filters down the filter path have to be kept
    let filterScenario = NaN;
    // 1 = use drilled item as only filter
    // 2 = do not use drilled item, but just its ancestors
    if (!currentlyFilteredCategories || currentlyFilteredCategories.length == 0) {
      filterScenario = 1;
    } else {
      if (parentIDs.includes(drillElementMod._id as string)) {
        filterScenario = 2;
      } else {
        filterScenario = 1;
      }
    }

    const drillPath: IResearchFilterDimensionCategory[] = JSON.parse(JSON.stringify(readCategoryDrillPath(context)));
    const identifier: string[] = [];
    const names: string[] = [];
    const IDs: string[] = [];
    drillPath.forEach(function(drill) {
      identifier.push(drill.identifier);
      names.push(drill.name);
      if (drill._id) IDs.push(drill._id);
    });
    const drillElementSet: IResearchFilterDimensionCategory = {
      _id: drillElementMod._id,
      identifier: drillElementMod.identifier,
      name: drillElementMod.name,
      parents_identifiers: identifier,
      parents_names: names,
      parents_ids: IDs,
      children: null,
    };

    if (filterScenario == 1) {
      commitSetDrillChangeElement(context, {drillChangeElement: [{...drillElementSet}], setFilter: true});
    } else {
      // just use ancestors as filtered items
      if (currentlyFilteredCategories) {
        const currentlyFiltered = currentlyFilteredCategories as IResearchFilterDimensionCategory[];
        const ancestors: IResearchFilterDimensionCategory[] = [];
        for (const curr of currentlyFiltered) {
          if (curr.parents_ids && drillElementMod._id && curr.parents_ids.includes(drillElementMod._id)) {
            ancestors.push(curr);
          }
        }
        commitSetDrillChangeElement(context, {drillChangeElement: ancestors, setFilter: true});
      }      
    } 
  },
};

const { dispatch } = getStoreAccessors<IResearchFilterState | any, State>('');

export const dispatchLoadResearchDimensions = dispatch(actions.loadResearchDimensions);
export const dispatchSetResearchDimensionArray = dispatch(actions.setResearchDimensionArray);
export const dispatchSetResearchDimensionCompleteSingle = dispatch(actions.setResearchDimensionCompleteSingle);
export const dispatchSetResearchDimensionAvailableAttributes = dispatch(actions.setResearchDimensionAvailableAttributes);
export const dispatchSetResearchDimensionFilterSelectAll = dispatch(actions.setResearchDimensionFilterSelectAll);
export const dispatchSetResearchDimensionFilterIndeterminate = dispatch(actions.setResearchDimensionFilterIndeterminate);
export const dispatchSetResearchDimensionRemoveAllFilters = dispatch(actions.setResearchDimensionRemoveAllFilters);
export const dispatchResearchCategoryDrillUp = dispatch(actions.setResearchCategoryDrillUp);
export const dispatchResearchCategoryDrillDown = dispatch(actions.setResearchCategoryDrillDown);
