import { ICategorylabSchema } from "./categoryLab";

export enum PlanFeatureEnum {
  RESEARCH = "RESEARCH",
  CLUSTER = "CLUSTER",
  CATEGORIZER_AGGREGATED = "CATEGORIZER_AGGREGATED",
  CATEGORIZER = "CATEGORIZER",
  CATEGORY_API = "CATEGORY_API",
  WEBAPP = "WEBAPP",
  INSIGHTS = "INSIGHTS",
  KPI = "KPI",
  SAVINGS = "SAVINGS",
  ACTION_LOG = "ACTION_LOG",
  REPORTS = "REPORTS",
  LLM = "LLM",
  COPILOT = "COPILOT",
  PIVOT = "PIVOT",
}

export enum UserRoleEnum {
  ADMIN = "ADMIN",
  MANAGEMENT = "MANAGEMENT",
  PURCHASER = "PURCHASER",
  CONSUMER = "CONSUMER",
}


export interface IOrganization {
  _id: string;
  name: string;
  db_name: string;
  current_periode_ends: string;
  current_api_quota: number;
  category_standard: string;
  plan: {
    max_api_quota: number;
    max_transaction_quota: number;
    max_user_quota: number;
    features: PlanFeatureEnum[];
  };
  transaction_options: {
    currency: string,
    spend_format: string,
    number_format: string,
    timestamp_dayfirst: boolean,
    timestamp_yearfirst: boolean
  },
  categorizer_schema: ICategorylabSchema[];
  category_load_full?: boolean;
  used_transaction_fields: string[];
}

export interface IOrganizationUpdate {
  name?: string;
  current_periode_ends?: string;
  current_api_quota?: number;
  plan?: {
    max_api_quota?: number;
    max_transaction_quota?: number;
    max_user_quota?: number;
    features?: PlanFeatureEnum[];
  };
}

export interface IOrganizationCreate {
  organization_in: {
    name?: string;
    current_periode_ends?: string;
    current_api_quota?: number;
    plan?: {
      max_api_quota?: number;
      max_transaction_quota?: number;
      max_user_quota?: number;
      features?: PlanFeatureEnum[];
    };
    categorizer_schema?: ICategorylabSchema[],
  },
  organization_to_db_in: {
    name?: string,
    tid?: string,
    admin_consent?: boolean,
    db_name?: string,
    subdomain?: string,
  }
}

// Export to to other modules
export interface IUserProfile {
  email: string;
  is_active: boolean;
  is_superuser: boolean;
  full_name: string;
  _id: string;
  features: PlanFeatureEnum[];
  role: UserRoleEnum;
  organization: IOrganization;
  organization_id?: string;
  refresh: boolean;
  options: IUserProfileOption;
}

export interface IUserProfileUpdate {
  email?: string;
  full_name?: string;
  password?: string;
  is_active?: boolean;
  is_superuser?: boolean;
  role?: UserRoleEnum;
  refresh?: boolean,
  options?: IUserProfileOption;
}

export interface IUserProfileCreate {
  email: string;
  organization_id?: string;
  full_name?: string;
  password?: string;
  is_active?: boolean;
  is_superuser?: boolean;
  role?: UserRoleEnum;
  options?: IUserProfileOption;
}

export interface IUserProfileOption {
  lang?: string;
  kpi_favorites?: string[];
}

export interface ICheckTaskResult {
  state: string,
  status: string,
  time?: string,
}

export interface IStats {
  fields: object,
  count: number,
  count_share: number,
}
